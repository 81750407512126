import get from "lodash.get";
import kebabcase from "lodash.kebabcase";
import { getChainIndex } from "~/components/layouts/homepage.helpers";

export const FOR_YOU_SURFACE_MV_TAB = "assembler-fy-mv-tab";
export const FOR_YOU_SURFACE_DEFAULT = "assembler-fy";

/**
 * Real-time Events (RTE) attributes for tracking information,
 *  intended for the child stories of a common container.
 * @param {object} content of the card
 * @returns data attrs relevant to RTE for attaching to a single card
 */
export const getRTEChildAttrs = (content) => {
  const content_id = get(content, "_id") || get(content, "tracking.post_id");
  const article_id =
    get(content, "additional_properties.for_you.article_id") || content_id;

  if (!article_id) return {};
  return {
    "data-articleid": article_id
  };
};

const tableKeyIndexes = {
  table0: 0,
  table1: 1,
  table2: 2,
  table3: 3,
  table4: 4,
  table5: 5,
  table6: 6,
  table7: 7,
  table8: 8,
  tableAd1: null, // Indexing is not supported for this
  table9: 9,
  allcontent: 1
};

/**
 *
 * @param {object} layoutCtx from SandwichLayoutContext
 * @param {object} tableContext from TableLayoutContext
 * @returns This is a string that consistently identifies where within the Washington Post website the
 *  module is located. A module should have a consistent name across all users that view it, and should be
 *  globally unique across all page types. For example in our personalized homepage test, the layout will
 *  be consistent with the “More Top Stories” being replaced with a personalized module. The same module
 *  name (e.g. “homepage_module_8” or “homepage_below_top_table”) should be applied to both versions.
 */
export const getRTEModulePositionClientSide = (
  layoutCtx = {},
  tableContext = {}
) => {
  const { tableKey } = layoutCtx;
  const { ref: tableRef } = tableContext;

  const cardsVisible = tableRef?.current?.querySelector(".card");
  if (!cardsVisible) return null;

  const tableIndex = tableKeyIndexes[tableKey]?.toString()?.padStart(2, "0");
  const chainIndex = parseInt(getChainIndex(tableRef?.current));

  return chainIndex && tableIndex ? `${chainIndex}${tableIndex}` : "";
};

// TODO: Decide on client-side (look up) v. server-side (look down). For now proceeding with server-side
// for now cuz the rest of the code currently works with server-side

/**
 * This is the server-side equivalent of the above method. The tableIndex will not be the same
 *
 * @param {object} layoutCtx from SandwichLayoutContext or any object with tableKey
 * @param {object} curationIndices from the master doc
 * @returns as above
 */
export const getRTEModulePosition = (layoutCtx = {}, curationIndices = {}) => {
  const { tableKey } = layoutCtx;
  const { chain } = curationIndices;
  const tableIndex = tableKeyIndexes[tableKey]?.toString()?.padStart(2, "0");
  const modulePosition =
    /\d+/.test(chain) && /\d+/.test(tableIndex) ? `${chain}${tableIndex}` : "";
  return !Number.isNaN(modulePosition)
    ? Number.parseInt(modulePosition)
    : undefined;
};

/**
 *
 * @param {object[]} items of client side content
 * @param {object} chainContext from ChainContext
 * @param {object} layoutCtx from SandwichLayoutContext
 * @returns A unique string identifying what category of articles are being proposed.
 *  This will typically correlate to the title shown to the reader (e.g. “Most read”= “most_read” or
 *  “Your followed topics”). If the same reason for showing articles occurs in two places, the string
 *  should be the same.
 */
export const getRTEModuleCategory = (
  items,
  chainContext = {},
  layoutCtx = {}
) => {
  const fyModuleCategory = get(
    items,
    "0.additional_properties.for_you.module_category"
  );

  if (fyModuleCategory) return fyModuleCategory;

  const { tableKey } = layoutCtx;

  const {
    linkGroup: chainGroup,
    labelShow: chainLabelShow,
    label: chainLabelText,
    displayName: chainName
  } = chainContext;
  const chainLabel = kebabcase(
    (!chainLabelShow ? undefined : chainLabelText)?.toLowerCase()
  );

  const chainCategory = chainGroup || chainLabel || chainName;
  if (tableKey === "allcontent") return chainCategory;

  const tableGroup = chainContext?.[`${tableKey}LinkGroup`];
  const tableLabelShow = chainContext?.[`${tableKey}LabelShow`];
  const tableLabelText = chainContext?.[`${tableKey}Label`];
  const tableLabel = kebabcase(
    (!tableLabelShow ? undefined : tableLabelText)?.toLowerCase()
  );

  const tableCategory = tableGroup || tableLabel;
  const category = tableCategory || chainCategory;

  return category;
};

const contentTypeAbbrevs = {
  homepage: "hp",
  front: "sf"
};
/**
 *
 * @param {object} context from AppContext
 * @param {string} modulePosition If there are multiple modules on the same page, what order are they in?
 * @param {object} contentConfigValues of the flex feature
 * @returns This is a string that consistently identifies where within the Washington Post website the
 *  module is located. A module should have a consistent name across all users that view it, and should be
 *  globally unique across all page types. For example in our personalized homepage test, the layout will
 *  be consistent with the “More Top Stories” being replaced with a personalized module. The same module
 *  name (e.g. “homepage_module_8” or “homepage_below_top_table”) should be applied to both versions.
 */
export const getRTESurface = (
  context = {},
  modulePosition,
  contentConfigValues = {}
) => {
  const { metaValue = () => {} } = context;
  const { surface: configSurface } = contentConfigValues;
  // NOTE: siteServiceSection is not required, hence "unknown" (perhaps get the assembler slug?)
  const contentType = metaValue("contentType");
  const section = metaValue("section");
  const contentTypeAbbrev = contentTypeAbbrevs[contentType] || "unknown";
  const typeAndSectionPrefix =
    contentType === "homepage"
      ? contentTypeAbbrev
      : `${contentTypeAbbrev}_${section}`;
  const rteSurface =
    typeAndSectionPrefix &&
    modulePosition &&
    `${typeAndSectionPrefix}_${modulePosition}`;
  return configSurface || rteSurface;
};

/**
 * Real-time Events (RTE) attributes for tracking information,
 *  intended for the common container of several child stories.
 *
 * @param {object[]} items of client side content
 * @param {string} surface This is a string that consistently identifies where within the Washington Post website the
 *  module is located. A module should have a consistent name across all users that view it, and should be
 *  globally unique across all page types. For example in our personalized homepage test, the layout will
 *  be consistent with the “More Top Stories” being replaced with a personalized module. The same module
 *  name (e.g. “homepage_module_8” or “homepage_below_top_table”) should be applied to both versions.
 * @param {string} moduleCategory A unique string identifying what category of articles are being proposed.
 *  This will typically correlate to the title shown to the reader (e.g. “Most read”= “most_read” or
 *  “Your followed topics”). If the same reason for showing articles occurs in two places, the string
 *  should be the same.
 * @param {string} modulePosition If there are multiple modules on the same page, what order are they in?
 * @param {boolean} includeFyAttrs If `fy` attributes should be included, overriding related `rte` attributes
 * @returns data attrs relevant to RTE for attaching to a parent container that includes a number of cards.
 */
export const getRTEParentAttrs = (
  items,
  surface,
  moduleCategory,
  modulePosition,
  includeFyAttrs
) => {
  const request_id = get(
    items,
    "0.additional_properties.for_you.request_id",
    undefined
  );

  return {
    ...(includeFyAttrs
      ? {
          ...(request_id ? { "data-fy-request-id": request_id } : {}),
          ...(surface ? { "data-fy-surface": surface } : {})
        }
      : {
          ...(request_id ? { "data-rte-request-id": request_id } : {}),
          ...(surface ? { "data-rte-surface": surface } : {})
        }),
    ...(moduleCategory ? { "data-rte-module-category": moduleCategory } : {}),
    ...(modulePosition ? { "data-rte-module-position": modulePosition } : {})
  };
};

// TODO: Document this
export const updateSurfaceLabel = ({
  findHeadlinesForSurface,
  appContext,
  curationIndices,
  customFields,
  tableKey,
  labelObj
}) => {
  const useSurfaceKey =
    tableKey === "chain" ? "labelUseSurface" : `${tableKey}LabelUseSurface`;
  const useSurface = customFields[useSurfaceKey];
  if (useSurface) {
    const surface = getRTESurface(
      appContext,
      getRTEModulePosition(
        { tableKey: tableKey === "chain" ? "allcontent" : tableKey },
        curationIndices
      ),
      {} // NOTE: would be contentConfigValues but not needed here
    );

    const headlinesForSurface = findHeadlinesForSurface({ surface });

    // TODO: Only replace if text value is the surface placeholder value

    if (labelObj[tableKey]) {
      labelObj[tableKey].label.text = headlinesForSurface?.content?.title
        ? headlinesForSurface?.content?.title
        : labelObj[tableKey].label.text;

      labelObj[tableKey].labelSecondary.text = headlinesForSurface?.content
        ?.subtitle
        ? headlinesForSurface?.content?.subtitle
        : labelObj[tableKey].labelSecondary.text;

      // TODO: How much mucking with show/hide should be done here?
      if (headlinesForSurface?.content) {
        if (!headlinesForSurface.content.title)
          labelObj[tableKey].label.show = false;
        if (!headlinesForSurface.content.subtitle)
          labelObj[tableKey].labelSecondary.show = false;
      }
      labelObj[tableKey].show =
        labelObj[tableKey].label.show || labelObj[tableKey].labelSecondary.show;
    }
  }
};
