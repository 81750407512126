import { useContent } from "fusion-content";
import { formatContentConfig } from "~/components/utilities/www-remover";

/**
 * The Catch-Up Module content hook.
 *
 * @example const content = useCatchUpContent("https://www.washingtonpost.com/utils/2025/02/06/get-caught-up/");
 * @param {string} contentPath the path to fetch catch-up content for
 * @returns {object}
 */
const useCatchUpContent = (contentPath) => {
  const filter = `
  {
    content_elements
    last_updated_date
  }
  `;
  const config = formatContentConfig({
    source: "prism-promo",
    query: { content_path: contentPath },
    filter
  });

  return useContent(config);
};

export default useCatchUpContent;
