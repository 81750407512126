import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { getWpAkWab } from "@washingtonpost/front-end-utils";
import { styled, theme, Box, Button, Icon } from "@washingtonpost/wpds-ui-kit";
import { ChevronUp, ChevronDown } from "@washingtonpost/wpds-assets";
// eslint-disable-next-line import/no-extraneous-dependencies
import Ticker from "react-ticker";
import { useFeatureFlag } from "~/components/utilities/use-feature-flag";
import { sendGAClick } from "../../../utilities/analytics/analytics";
import {
  orderTickerItemsBySource,
  TICKER_HEIGHT,
  TICKER_FONT_SIZE,
  TICKER_SPEED,
  TICKER_TITLE,
  SHOW_TITLE,
  getAggTickerVisibility,
  setAggTickerVisibility
} from "./utils";
import { usePrismNewsTickerData } from "./api";
import { NewsTickerItem } from "./NewsTickerItem";

const imageMask = SHOW_TITLE
  ? "linear-gradient(to right, transparent calc(140px), black 14%, black calc(95% - 180px), transparent calc(95%))"
  : "linear-gradient(to right, black 85%, transparent 95%)";
const TickerLabel = styled("span", {
  paddingRight: theme.space["050"],
  fontWeight: 900,
  display: "inline-block",
  flexShrink: 0,
  left: 30,
  position: "absolute",
  bottom: 14,
  zIndex: 12,
  transition: "opacity 0.5s ease-out"
});

const Wrapper = styled(Box, {
  position: "fixed",
  left: 0,
  right: 0,
  bottom: 0,
  height: TICKER_HEIGHT,
  fontSize: TICKER_FONT_SIZE,
  zIndex: 5,
  "@sm": {
    display: "none"
  },
  "@media (pointer: coarse)": {
    display: "none"
  }
});

const VisibilityWrapper = styled(Box, {
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  height: TICKER_HEIGHT,
  background: `${theme.colors.gray700}`,
  zIndex: 11,
  border: `1px solid ${theme.colors.gray400}`,
  transition: "transform 0.3s ease-out, opacity 0.3s ease-out"
});

const InternalTickerWrapper = styled(Box, {
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  height: TICKER_HEIGHT,
  zIndex: 12,
  transition: "transform 0.5s ease-out, opacity 0.5s ease-out"
});

const VisibilityButton = styled(Button, {
  position: "absolute",
  top: "50%",
  bottom: 0,
  right: 8,
  zIndex: 12,
  width: "87px",
  fontSize: TICKER_FONT_SIZE,
  transition: "opacity 0.5s ease-out",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transform: "translateY(-50%)",
  "& span": {
    position: "relative"
  },
  "& span::before": {
    content: "Hide",
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 1,
    transition: "opacity 0.2s ease-out"
  },
  "& span::after": {
    content: "Show",
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0,
    transition: "opacity 0.2s ease-out"
  },
  "&.is-hidden span::before": {
    opacity: 0
  },
  "&.is-hidden span::after": {
    opacity: 1
  }
});

export const FixedTickerWrapper = ({
  children,
  showTicker,
  showContent,
  showHideShowButton,
  showTitle,
  onHideShowButtonClick,
  ...rest
}) => {
  const [wrapperIsVisible, setWrapperIsVisible] = useState(false);
  const [buttonIsVisible, setButtonIsVisible] = useState(showHideShowButton);
  const [contentIsVisible, setContentIsVisible] = useState(showContent);
  const [titleIsVisible, setTitleIsVisible] = useState(showTitle);
  const [appearedOnce, setAppearedOnce] = useState(false);

  useEffect(() => {
    setWrapperIsVisible(showTicker);
    if (!appearedOnce && showTicker) {
      setAppearedOnce(true);
    }
  }, [showTicker, appearedOnce]);

  useEffect(() => {
    setTitleIsVisible(showTitle);
  }, [showTitle]);

  useEffect(() => {
    setButtonIsVisible(showHideShowButton);
  }, [showHideShowButton]);

  useEffect(() => {
    setContentIsVisible(showContent);
  }, [showContent]);

  const handleVisibilityToggle = () => {
    sendGAClick("aggregator", "onpage-click", "agg_ticker_interaction", {
      event: "site-aggregator-click",
      eventDimension: wrapperIsVisible ? "hide" : "show"
    });
    setAggTickerVisibility(!wrapperIsVisible);
    if (appearedOnce) {
      setWrapperIsVisible(!wrapperIsVisible);
      if (SHOW_TITLE) {
        setTitleIsVisible(!titleIsVisible);
      }
    }
    // else {
    onHideShowButtonClick(!wrapperIsVisible);
    // }
  };

  return (
    <Wrapper {...rest}>
      <TickerLabel
        style={{
          opacity: titleIsVisible ? 1 : 0,
          transform: titleIsVisible
            ? "translateY(0)"
            : `translateY(${TICKER_HEIGHT}px)`
        }}
      >
        {TICKER_TITLE}
      </TickerLabel>
      <VisibilityButton
        variant="secondary"
        icon="left"
        density="compact"
        onClick={handleVisibilityToggle}
        className={wrapperIsVisible ? "" : "is-hidden"}
        style={{ opacity: buttonIsVisible ? 1 : 0 }}
      >
        <span>{wrapperIsVisible ? "Hide" : "Show"}</span>
        <Icon size="100">
          {wrapperIsVisible ? <ChevronDown /> : <ChevronUp />}
        </Icon>
      </VisibilityButton>
      <VisibilityWrapper
        style={{
          opacity: wrapperIsVisible ? 1 : 0,
          transform: wrapperIsVisible
            ? "translateY(0)"
            : `translateY(${TICKER_HEIGHT}px)`
        }}
      >
        <InternalTickerWrapper
          style={{
            opacity: contentIsVisible ? 1 : 0
          }}
        >
          {children}
        </InternalTickerWrapper>
      </VisibilityWrapper>
    </Wrapper>
  );
};

export const ExternalNewsTicker = () => {
  const [tickerItemsBySource, setTickerItemsBySource] = useState({});
  const [tickerPaused, setTickerPaused] = useState(false);
  const [showTickerForVeryFirstTime, setShowTickerForVeryFirstTime] =
    useState(false);
  const [showContent, setShowContent] = useState(false);
  const [moveTicker, setMoveTicker] = useState(false);
  const [showHideShowButton, setShowHideShowButton] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const [isReady, setReady] = useState(false);
  const [showOnLoad] = useState(getAggTickerVisibility());

  const showTickerFeatureFlag = useFeatureFlag("showExternalNewsTicker");
  const showTickerImageFeatureFlag = useFeatureFlag(
    "showExternalNewsTickerImage"
  );
  const isInFiftyPercentAllocation = getWpAkWab("50/50") === "0";
  const isOk = showTickerFeatureFlag && isInFiftyPercentAllocation;

  const tickerItems = usePrismNewsTickerData(isOk);

  useEffect(() => {
    setReady(!!tickerItems && !tickerItems?.isLoading);
  }, [tickerItems]);

  const data = useMemo(
    () => orderTickerItemsBySource(tickerItemsBySource),
    [tickerItemsBySource]
  );

  useEffect(() => {
    if (showOnLoad && !showTickerForVeryFirstTime && data.length) {
      setShowTickerForVeryFirstTime(true);
    }
  }, [showOnLoad, showTickerForVeryFirstTime, data]);

  useEffect(() => {
    const startInitialSequence = () => {
      if (!showTitle && SHOW_TITLE) {
        setTimeout(() => {
          setShowTitle(true);
        }, 1000);
      }
      if (!showContent) {
        setTimeout(
          () => {
            setShowContent(true);
          },
          SHOW_TITLE ? 2000 : 1000
        );
      }
      if (!moveTicker) {
        setTimeout(
          () => {
            setMoveTicker(true);
          },
          SHOW_TITLE ? 3000 : 2000
        );
      }
    };
    if (showTickerForVeryFirstTime) {
      startInitialSequence();
    }
  }, [showTickerForVeryFirstTime, showTitle, showContent, moveTicker]);

  useEffect(() => {
    if (data.length && !showHideShowButton) {
      let timeout;
      if (showOnLoad) {
        timeout = SHOW_TITLE ? 4000 : 3000;
      } else {
        timeout = 1000;
      }
      setTimeout(() => {
        setShowHideShowButton(true);
      }, timeout);
    }
  }, [data, showHideShowButton, showOnLoad]);

  const onHideShowButtonClick = (switchedToVisible) => {
    if (showTickerForVeryFirstTime) {
      if (switchedToVisible) {
        setTickerPaused(false);
      } else {
        setTickerPaused(true);
      }
    } else if (switchedToVisible) {
      setShowTickerForVeryFirstTime(true);
    }
  };

  const tickerWrapperProps = useMemo(() => {
    // Calculate the props for FixedTickerWrapper based on the state and values inside ExternalNewsTicker
    const props = {
      showTicker: showTickerForVeryFirstTime,
      showHideShowButton,
      showContent,
      showTitle
    };
    return props;
  }, [showTickerForVeryFirstTime, showHideShowButton, showContent, showTitle]);

  useEffect(() => {
    if (isOk && isReady && tickerItems) {
      try {
        setTickerItemsBySource((prev) => {
          const next = { ...prev };
          Object.entries(tickerItems).forEach(([key, vals]) => {
            next[key] = Array.from(new Set([...(next[key] || []), ...vals]));
          });
          return next;
        });
      } catch (e) {
        setTickerItemsBySource({});
      }
    }
  }, [isOk, isReady, tickerItems]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setTickerPaused(true);
      } else {
        setTickerPaused(false);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  if (!data.length) return null;
  if (!isOk) return null;

  return (
    <FixedTickerWrapper
      {...tickerWrapperProps}
      onHideShowButtonClick={onHideShowButtonClick}
      onPointerEnter={() => setTickerPaused(true)}
      onPointerLeave={() => setTickerPaused(false)}
    >
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .ticker {
              overflow: visible !important;
              mask-image: ${imageMask};
              -webkit-mask-image: ${imageMask};
              width: 100%;
              overflow-x: scroll;
              transition: opacity 0.5s ease-out;
              top: 2px;
            }
            [data-radix-popper-content-wrapper] {z-index: 10 !important;}`
        }}
      />
      <Ticker
        height={TICKER_HEIGHT}
        mode="chain"
        move={moveTicker && !tickerPaused}
        offset={SHOW_TITLE ? 200 : 10}
        speed={tickerPaused ? 0 : TICKER_SPEED}
      >
        {({ index }) => {
          // allows for infinite looping of the ticker
          const item = data[index % data.length];

          return item ? (
            <NewsTickerItem
              item={item}
              key={item.url}
              showImage={showTickerImageFeatureFlag}
            />
          ) : null;
        }}
      </Ticker>
    </FixedTickerWrapper>
  );
};
FixedTickerWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  showTicker: PropTypes.bool.isRequired,
  showContent: PropTypes.bool.isRequired,
  showHideShowButton: PropTypes.bool.isRequired,
  showTitle: PropTypes.bool.isRequired,
  onHideShowButtonClick: PropTypes.func.isRequired
};
