/**
 * @param {object[]} contentElements from the output of prism-content-api
 * @returns {object[]} contentElements but each section of content that looks like
 *    <h#></h#>
 *    <*></*>...
 *  has been wrapped into <section> tags
 */
const sectionize = (contentElements = []) => {
  let currSection = [];
  let currHeader;
  const sections = contentElements.reduce((acc, contentElement) => {
    const { type } = contentElement;

    if (type !== "header") {
      currSection.push(contentElement);
      return acc;
    }
    if (currSection?.length) {
      acc.push({
        type: "section",
        "aria-labelledby": currHeader?._id,
        title: currHeader?.content,
        items: [...currSection]
      });
    }

    currSection = [contentElement];
    currHeader = contentElement;
    return acc;
  }, []);
  if (currSection?.length) {
    sections.push({
      type: "section",
      title: currHeader?.content,
      items: [...currSection]
    });
  }
  return sections;
};

export default sectionize;
