import PropTypes from "prop-types";
import { formatInTimeZone } from "date-fns-tz";

import get from "lodash.get";

/**
 *
 * @param {object} data - Any object that contains values you want to get. Can be an ANS object or
 * a custom fields object
 * @param {object} keys - Flat object of keys and values telling where to grab the value in ANS
 * via lodash.
 * Keys have structure of [ANSBackingContentKey, firstPriorityCustomField, secondPriorityCustomField, etc]
 * The customField keys are listed in order of priority -- if keys[1] has content, that overrides ANSkey
 * Otherwise it checks all customField keys
 * If still empty, checks the ANSBackingContentKey (keys[0])
 * @param {object} overrides - The override key and values associated with the prop for the component.
 * @returns {object} an object with the props needed by the consuming component
 */
export const fetchProps = ({ data, keys, overrides }) => {
  const separatedProps = Object.entries(keys).map(([key, fieldKeys]) => {
    const [ANSKey, ...customFieldKeys] = fieldKeys;

    let override = "";
    let index = 0;
    while (!override && index < customFieldKeys.length) {
      override = get(overrides, customFieldKeys[index], "");
      index += 1;
    }

    return {
      [key]:
        typeof override === "number" ||
        typeof override === "boolean" ||
        override
          ? override
          : get(data, ANSKey)
    };
  });

  return Object.assign(...separatedProps);
};

// Inspired by https://stackoverflow.com/questions/30474506/replace-part-of-string-with-tag-in-jsx
/**
 * This acts almost like a search/replace where the replace is JSX. Replace can also be a string.
 * The "almost" comes from the fact that it does not return a string (it can't) but rather
 * an array of strings/jsx
 *
 * @param {string} text - The string subject to search/replace
 * @param {string} search - The search string
 * @param {function} fn - Function that should basically be (part, idx) => [part, JSX] (JSX may need unique key)
 * @return {array} - Array of strings and JSX that can be rendered thus: <Fragment>{array}</Fragment>
 */
export const searchAndReplaceWithJsx = (text, search, fn) => {
  let result = [];
  text.split(search).forEach((part, idx) => {
    result = result.concat(fn(part, idx));
  });
  result.pop();
  return result;
};

searchAndReplaceWithJsx.propTypes = {
  text: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  fn: PropTypes.func.isRequired
};

/**
 * If beforeTime is earlier than time by more than beforeThreshold, return beforeText. If no
 * beforeTime, return beforeText. This is a way to just prepend text, say "Published" when the
 * publish date is used.
 *
 * This method was created for the case when time is the display date, beforeTime is the
 * first display date and the before text is "Updated". Often the first display
 * date is only seconds earlier than the display date date which is why there is a beforeThreshold
 *
 * @param {time} string - The date.
 * @param {beforeText} string - Optional before text.
 * @param {beforTime} string - Optional. If passed, used to determine beforeText if no beforeText
 * @param {beforThreshold} number - Optional. Time in seconds. Defaults to 60.
 *  If (time - beforeTime) > beforeThreshold, then show beforeText.
 *
 * @return {string} - The before text to show. It might be beforeText or ""
 */
const getBeforeText = ({ time, beforeTime, beforeThreshold, beforeText }) => {
  if (beforeText && time && beforeTime) {
    const t = new Date(time).getTime();
    const b = new Date(beforeTime).getTime();
    // If time is gt beforeTime by more than beforeThreshold and time is not in the future, return beforeText
    if (
      !Number.isNaN(t) &&
      !Number.isNaN(b) &&
      t - b > beforeThreshold * 1000 &&
      t - new Date().getTime() <= 0
    ) {
      return beforeText;
    }
    return "";
  }
  return beforeText;
};

// Inspired by https://johnresig.com/files/pretty.js
/**
 * Takes an ISO time and returns a string representing how long ago the date represents.
 * Largest unit it reports back is weeks. The threshold defaults to two hours, beyond
 * which nothingn is returned
 *
 * @param {time} string - The date.
 * @param {comparisonTime} string - Optional. The date to compare. Defaults to now. Implemented for testing.
 * @param {thresholdInHours} number - Threshold in hours, beyond which no date will be returned.
 * @param {beforeText} string - An optional string to prepend to the returned pretty date
 *  If no beforeTime, it always shows. Otherwise, it conditionallly shows. See below.
 * @param {beforTime} string - Optional. If passed, used to determine beforeText if no beforeText
 * @param {beforThreshold} number - Optional. Time in seconds. Defaults to 60.
 *  If (time - beforeTime) > beforeThreshold, then show beforeText.
 * @return {string} - A pretty date
 */
export const getTimestampInfo = ({
  time,
  comparisonTime,
  thresholdInHours = 2,
  beforeText = "",
  beforeTime,
  beforeThreshold = 60,
  format
}) => {
  if (!time) return {};

  let ts;

  // adding this for clarity
  const unitsInSeconds = {
    oneMinute: 60,
    twoMinutes: 120,
    oneHour: 3600,
    twoHours: 7200,
    oneDay: 86400
  };

  const { oneMinute, twoMinutes, oneHour, twoHours, oneDay } = unitsInSeconds;

  const comparisonDate = comparisonTime ? new Date(comparisonTime) : new Date();

  const date = new Date(time);
  const diff = (comparisonDate - new Date(date).getTime()) / 1000;

  const absDiff = Math.abs(diff);
  const isFuture = diff < 0;

  const minDiff = Math.floor(diff / oneMinute);
  const hourDiff = Math.floor(diff / oneHour);

  // This one has to be absolute
  const dayDiff = Math.floor(absDiff / oneDay);

  // START: Calculate diff in calendar days by using midnight dates
  // NOTE: Unless we calculate these dates client side, this will not be fully accurate
  // Care would have to be taken to avoid CLS, of course
  const dateMidnight = new Date(
    `${date.getMonth() + 1}/${date.getDate()}/${date.getYear()}`
  );
  const comparisonDateMidnight = new Date(
    `${
      comparisonDate.getMonth() + 1
    }/${comparisonDate.getDate()}/${comparisonDate.getYear()}`
  );
  const calendarDayDiff =
    Math.abs(dateMidnight - comparisonDateMidnight) / (oneDay * 1000);

  beforeText = getBeforeText({ time, beforeTime, beforeThreshold, beforeText });

  // NOTE: for 'fixed date' there will be a format
  if (format) {
    return {
      diffInCalendarDays: calendarDayDiff,
      diffInMins: minDiff,
      ts: date
        ? `${beforeText}${formatInTimeZone(date, "America/New_York", format)}`
        : date
    };
  }

  if (Number.isNaN(dayDiff) || (!isFuture && hourDiff > thresholdInHours))
    return {
      diffInCalendarDays: calendarDayDiff,
      diffInMins: minDiff,
      ts: ts ? `${beforeText}${ts}` : ts
    };

  const suffix = isFuture ? ` from now` : ` ago`;

  if (dayDiff === 0) {
    // anything less than 24 hours old
    if (absDiff < oneMinute) ts = "Just now";
    else if (absDiff < twoMinutes) ts = `1 minute${suffix}`;
    else if (absDiff < oneHour)
      ts = `${Math.floor(absDiff / oneMinute)} minutes${suffix}`;
    else if (absDiff < twoHours) ts = `1 hour${suffix}`;
    else ts = `${Math.floor(absDiff / oneHour)} hours${suffix}`;
  } else if (dayDiff === 1 && calendarDayDiff === 1) {
    // between 24 and 48 hours
    ts = isFuture ? "Tomorrow" : "Yesterday";
  } else if (dayDiff < 7) ts = `${calendarDayDiff} days${suffix}`;
  else if (Math.ceil(dayDiff / 7) === 1)
    ts = `${Math.ceil(dayDiff / 7)} week${suffix}`;
  else ts = `${Math.ceil(dayDiff / 7)} weeks${suffix}`;

  return {
    diffInCalendarDays: calendarDayDiff,
    diffInMins: minDiff,
    ts: ts ? `${beforeText}${ts}` : ts
  };
};

getTimestampInfo.propTypes = {
  time: PropTypes.string.isRequired,
  comparisonTime: PropTypes.string,
  threshold: PropTypes.number
};

/**
 * Takes a number (n) and an array of numbers (order) and returns an array for looping through
 * The order array takes precedence, but will be sliced to length n. If no order array,
 * the array [1,2,3,...n] will be returned
 *
 * @param {n} number - The number
 * @param {order} string - Comma-separated string of numbers like "2,1,3"
 * @return {array} - Array for looping
 */
export const getLoopControl = ({ n, order }) => {
  if (order && order.join(",")) {
    return order
      .slice(0, n)
      .map((v) => Number.parseInt(v, 10))
      .filter((v) => !Number.isNaN(v));
  }
  // NOTE: creates an array with length of n starting at 1 like [1,2,3,...]
  return new Array(n).fill(1).map((v, i) => i + 1);
};

getLoopControl.propTypes = {
  n: PropTypes.number,
  order: PropTypes.arrayOf(PropTypes.number)
};
