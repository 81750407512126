import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { theme, Icon } from "@washingtonpost/wpds-ui-kit";
import Play from "@washingtonpost/wpds-assets/asset/play";
import { getClasses } from "@washingtonpost/front-end-utils";
import { isSafeListed } from "../utilities/safelist";

const UnassignedSafeFeature = ({ child }) => <Fragment>{child}</Fragment>;

const UnassignedUnsafeFeature = ({ child, type }) => (
  <Fragment>
    <div className="z-7 absolute w-100 h-100 o-75 bg-black white center">
      <div className="pa-md">
        Features of the type
        <div className="font-bold" style={{ color: "#ffa500" }}>
          {type}
        </div>
        are banned from the multi-chain table
      </div>
    </div>
    {child}
  </Fragment>
);

const UnassignedFeature = ({ child, props, outputType }) =>
  isSafeListed({ props, outputType }) ? (
    <UnassignedSafeFeature child={child} />
  ) : (
    <UnassignedUnsafeFeature child={child} type={props.type} />
  );

export const UnassignedZone = ({
  displayName,
  unassignedTableKey,
  unassignedLayout,
  unassignedChildrenIds,
  itemsPerFeatureMap,
  childProps,
  outputType,
  children
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const style = {
    position: "relative",
    marginRight: theme.space["050"],
    color: theme.colors.gray80,
    zIndex: 3,
    ...(isExpanded ? { transform: "rotate(90deg)" } : {})
  };

  return (
    <div
      className="hide-wrapper hpgrid-max-width ma-auto gray-dark mb-xl relative z1"
      style={{ background: "#f2dede" }}
    >
      <div className="pa-xs">
        <button
          className="gray-dark bg-transparent font-xs"
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          <Icon label={isExpanded ? "collapse" : "expand"}>
            <Play style={style} />
          </Icon>
          {displayName ? `The chain "${displayName}"` : "This chain"} has{" "}
          {unassignedChildrenIds.length} unassigned feature
          {unassignedChildrenIds.length > 1 ? "s" : ""}. Click to{" "}
          {isExpanded ? "collapse" : "expand"}.
        </button>
      </div>
      {isExpanded && (
        <Fragment>
          <div className="pl-md pb-sm">
            The features listed below are assigned to the top table but will not
            display to readers. You can leave them here - hidden. You can add
            them to a table/column or you can remove them.
          </div>
          <div
            key={unassignedTableKey}
            className={`
              table-in-grid hpgrid hpgrid-item hpgrid-item--c-start hpgrid-item--c-spans hpgrid-item--r-spans
            `}
            style={unassignedLayout[unassignedTableKey].styles}
          >
            {unassignedChildrenIds.map((id) => (
              <div
                className={`card relative hpgrid-item hpgrid-item--c-start hpgrid-item--c-spans hpgrid-item--r-spans ${getClasses(
                  "",
                  { "unassigned-feed": itemsPerFeatureMap[id] >= 1 }
                )}`}
                style={{
                  "--c-start-mx": 0,
                  "--c-start-lg": 0,
                  "--c-start-md": 0,
                  "--c-start-sm": 0,
                  "--c-start-xs": 0,
                  "--c-span-mx": 4,
                  "--c-span-lg": 4,
                  "--c-span-md": 4,
                  "--c-span-sm": 5,
                  "--c-span-xs": 1
                }}
                key={`missing_${id}`}
              >
                <span
                  className="absolute dib"
                  style={{
                    top: 0,
                    left: `${itemsPerFeatureMap[id] <= 1 ? "-20px" : "-30px"}`
                  }}
                >
                  {itemsPerFeatureMap[id] <= 1 && id}
                  {itemsPerFeatureMap[id] > 1 && (
                    <div style={{ textAlign: "center" }}>
                      {id}
                      <div style={{ fontSize: "12px" }}>
                        feed
                        <div>1 of {itemsPerFeatureMap[id]}</div>
                      </div>
                    </div>
                  )}
                </span>
                <UnassignedFeature
                  props={childProps[id - 1]}
                  child={children[id - 1]}
                  outputType={outputType}
                />
              </div>
            ))}
          </div>
        </Fragment>
      )}
    </div>
  );
};

UnassignedSafeFeature.propTypes = {
  child: PropTypes.object
};

UnassignedUnsafeFeature.propTypes = {
  child: PropTypes.object,
  type: PropTypes.string
};

UnassignedFeature.propTypes = {
  child: PropTypes.object,
  props: PropTypes.object,
  type: PropTypes.string,
  outputType: PropTypes.string
};

UnassignedZone.propTypes = {
  displayName: PropTypes.string,
  layoutName: PropTypes.string,
  unassignedTableKey: PropTypes.string,
  unassignedLayout: PropTypes.object,
  unassignedChildrenIds: PropTypes.array,
  itemsPerFeatureMap: PropTypes.object,
  childProps: PropTypes.array,
  outputType: PropTypes.string,
  children: PropTypes.any
};
