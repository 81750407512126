import PropTypes from "prop-types";
import { styled, theme } from "@washingtonpost/wpds-ui-kit";

const StyledAnchor = styled("a", {
  color: theme.colors["gray700-static"]
});

const Headline = (props) => {
  const {
    className = "",
    videoData = {},
    isAdmin,
    displayWithoutVideo
  } = props;
  const { headline, headlinePrefix, url, videoLabel, count } = videoData;
  const noVideo = displayWithoutVideo ? "no-video" : "";
  return (
    <div
      className={`live-headline ${noVideo} ${className} inline-flex self-center`}
    >
      <StyledAnchor className="pointer" href={url} data-link-detail={count}>
        <strong className="bold">{headlinePrefix}</strong>
        {headline}
      </StyledAnchor>
      {isAdmin === true && (
        <span className="light italic pl-xs red">[{videoLabel}]</span>
      )}
    </div>
  );
};

Headline.propTypes = {
  className: PropTypes.string,
  displayWithoutVideo: PropTypes.bool,
  isAdmin: PropTypes.bool,
  videoData: PropTypes.object
};

export default Headline;
