/* eslint-disable react/prop-types */
import { Orca } from "@washingtonpost/media-components/core/index";
import { isProd } from "~/components/utilities/env";

const VideoElement = ({
  ads,
  aspectRatio,
  autoplay,
  customBtnText,
  durationOrLive,
  unresizedAlternateArt,
  muted,
  playthrough,
  showPreview2,
  uuid,
  videoData,
  playerType
}) => {
  const props = {
    uuid,
    video: videoData,
    config: {
      hasAds: ads,
      aspectRatio,
      alternateArt: unresizedAlternateArt,
      allowPlaythrough: playthrough,
      showDurationOrLive: durationOrLive,
      isLivePreviewEnabled: showPreview2,
      isSticky: false,
      allowShare: true,
      showBtnText: !!customBtnText,
      customBtnText,
      isAutoplayOverride: autoplay,
      isMutedOverride: muted,
      playerTypeOverride: playerType,
      ...(!isProd() && {
        adOverrides: { adZoneOverride: "wpni.video.test/appsalwayson" }
      })
    }
  };

  return <Orca {...props} />;
};

export default VideoElement;
