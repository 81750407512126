import { styled, theme } from "@washingtonpost/wpds-ui-kit";
import { Fragment } from "react";
import { ContentPropTypes } from "~/proptypes/catch-up-module";

const Bullet = styled("div", {
  width: theme.sizes["050"],
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.space["075"],
  padding: 0
});
const Spacer = styled("div", {
  width: "1px",
  height: "2px"
});
const Marker = styled("div", {
  width: "6px",
  height: "6px",
  backgroundColor: theme.colors.blue80
});
const Line = styled("div", {
  width: "1px",
  flexGrow: 1,
  backgroundColor: theme.colors.gray300
});
const Text = styled("div", {
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "stretch",
  padding: 0
});
const Span = styled("span", {
  alignSelf: "stretch",
  color: theme.colors.gray80,
  fontFamily: theme.fonts.meta,
  fontWeight: theme.fontWeights.light,
  fontSize: theme.fontSizes["100"],
  b: {
    color: theme.colors.gray20
  },
  a: {
    textDecoration: "underline"
  }
});

/**
 * Content wrapper component for prism-content-api list items.
 */
const ListItem = ({ element }) => {
  const { type, _id, content } = element;

  const Li = styled("li", {
    alignSelf: "stretch",
    display: "flex !important",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: theme.space["050"],
    padding: 0
  });

  switch (type) {
    case "text":
      return (
        <Li key={_id}>
          <Bullet>
            <Spacer />
            <Marker />
            <Line />
          </Bullet>
          <Text>
            <Span dangerouslySetInnerHTML={{ __html: content }} />
          </Text>
        </Li>
      );
    default:
      return null;
  }
};

/**
 * Content wrapper component for prism-content-api lists.
 */
const List = ({ element }) => {
  const { items = [], list_type: type } = element || {};

  const Ul = styled("ul", {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
    padding: 0,
    margin: 0,
    gap: theme.space["075"],
    listStyleType: "none",
    [Bullet]: {
      height: theme.sizes["125"]
    },
    [`${Line}, ${Spacer}`]: {
      display: "none"
    }
  });
  const Ol = styled("ol", {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
    padding: 0,
    margin: 0,
    listStyleType: "none",
    [Marker]: {
      borderRadius: "50%"
    },
    [Bullet]: {
      minWidth: "9px",
      alignSelf: "stretch",
      flexDirection: "column",
      justifyContent: "flex-start",
      gap: theme.space["025"],
      flexGrow: 0
    },
    [`li:last-child ${Bullet}`]: {
      padding: "7px 0 0",
      flexGrow: 0
    },
    [Text]: {
      padding: "0 0 8px",
      gap: "2px"
    },
    [`li:last-child ${Line}, li:last-child ${Spacer}`]: {
      display: "none"
    }
  });
  const typeToComponent = {
    unordered: Ul,
    ordered: Ol
  };
  const Component = typeToComponent[type] || Fragment;

  return (
    <Component>
      {items.map((e, i) => (
        <ListItem key={i} element={e} />
      ))}
    </Component>
  );
};
List.propTypes = ContentPropTypes;
ListItem.propTypes = ContentPropTypes;

export default List;
