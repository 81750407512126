import { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Box, Tooltip, styled, theme } from "@washingtonpost/wpds-ui-kit";
import { getTimestampInfo } from "~/shared-components/story-card/_utilities/data";
import { TICKER_HEIGHT } from "./utils";
import { sendGAClick } from "../../../utilities/analytics/analytics";
import getResizedUrl from "~/components/core/elements/image/resize-url";

const THREE_DAYS = 1000 * 60 * 60 * 24 * 3;

const TickerItem = styled(Box, {
  display: "flex",
  flexShrink: 0,
  alignItems: "center",
  height: TICKER_HEIGHT,
  padding: "0 $075",
  position: "relative",
  width: "max-content",
  cursor: "pointer"
});

const TickerItemTooltip = styled(Tooltip.Content, {
  width: "30rem !important",
  background: "$gray700",
  borderRadius: `$050 !important`,
  boxShadow: `4px 4px 12px 4px ${theme.colors.gray200}`,
  padding: "0 !important",
  border: `0 !important`,
  position: "relative",
  "&::after": {
    width: "100%",
    height: "8px",
    content: '" "',
    position: "absolute",
    pointerEvents: "auto",
    bottom: 0,
    left: 0,
    transform: "translateY(100%)",
    zIndex: 10
  }
});

const FlexColumn = styled("div", {
  display: "flex",
  flexDirection: "column"
});

const StyledAnchor = styled("a", {
  color: "inherit",
  textDecoration: "none"
});

const StyledCard = styled("div", {
  display: "flex",
  width: "30rem",
  variants: {
    showImage: {
      true: {
        height: "164px"
      },
      false: {
        height: "146px"
      }
    }
  }
});

const StyledTextContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$100",
  padding: "$100"
});

const StyledTopLine = styled("div", {
  display: "flex",
  justifyContent: "space-between"
});

const StyledSource = styled("p", {
  fontSize: "$100",
  fontWeight: "$bold"
});

const StyledTimestamp = styled("p", {
  fontSize: "$087",
  color: "$gray80",
  fontWeight: "$light",
  fontFamily: "$meta"
});

const StyledDescription = styled("p", {
  display: "-webkit-box",
  fontSize: "$087",
  overflow: "hidden",
  "-webkit-box-orient": "vertical",
  variants: {
    showImage: {
      true: {
        height: "4.375rem",
        "-webkit-line-clamp": 4
      },
      false: {
        height: "3.25rem",
        "-webkit-line-clamp": 3
      }
    }
  }
});

const StyledSourceUrl = styled("p", {
  fontSize: "$087",
  textDecoration: "underline"
});

const StyledImage = styled("img", {
  height: "100%",
  width: "auto",
  maxWidth: "164px",
  maxHeight: "164px",
  aspectRatio: "1/1",
  objectFit: "cover",
  marginRight: "$025",
  flexShrink: 0,
  borderRadius: "$050 0 0 $050"
});

const SourceLabel = styled("span", {
  paddingRight: "$050",
  fontWeight: 900,
  display: "inline-block",
  flexShrink: 0
});

const HeadlineLabel = styled("span", {
  textDecoration: "none",
  "&:hover": {
    textDecoration: "none",
    color: "$gray80"
  }
});

export const NewsTickerItem = ({ item, showImage }) => {
  const [openTooltip, setOpenTooltip] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [fallbackImageLoaded, setFallbackImageLoaded] = useState(false);

  // these are temporary as we adjust the api data contract
  const headline = useMemo(
    () => item.og_title || item.headline,
    [item.og_title, item.headline]
  );
  const description = useMemo(
    () => item.og_description || item.description,
    [item.og_description, item.description]
  );
  const imageUrl = useMemo(
    () => item.og_image || item.image,
    [item.og_image, item.image]
  );

  const urlObject = useMemo(() => {
    try {
      return new URL(item.url);
    } catch (_) {
      return null;
    }
  }, [item.url]);

  const timestamp = useMemo(() => {
    // NOTE: This object does not have a display_date so using date_created. Do not follow this pattern if possible
    if (item.date_created) {
      const date = new Date(`${item.date_created}Z`); // Append 'Z' to indicate UTC timezone
      const { ts } = getTimestampInfo({
        time: date,
        thresholdInHours: THREE_DAYS
      });

      return ts || null;
    }

    return null;
  }, [item.date_created]);

  useEffect(() => {
    if (showImage && imageUrl) {
      const img = new Image();
      img.src = getResizedUrl(imageUrl, { w: 164, h: 164 });
      img.onerror = () => {
        if (img.src !== imageUrl) {
          img.src = imageUrl;
        }
      };
      img.onload = () => {
        setImageLoaded(true);
        if (img.src === imageUrl) setFallbackImageLoaded(true);
      };
    }
  }, [imageUrl, showImage]);

  const onClick = () => {
    sendGAClick("aggregator", "external-link-click", "agg_ticker_link", {
      external_url: urlObject ? urlObject.href : item.url,
      eventDimension: urlObject ? urlObject.hostname : ""
    });
  };

  const onTooltipOpen = (isOpen) => {
    if (isOpen) {
      sendGAClick("aggregator", "external-link-hover", "agg_ticker_link", {
        external_url: urlObject ? urlObject.href : item.url,
        eventDimension: urlObject ? urlObject.hostname : ""
      });
    }
  };

  const assembleUrl = useCallback((url) => {
    try {
      const urlObj = new URL(url);
      if (urlObj.hostname === "www.washingtonpost.com") {
        urlObj.searchParams.set("itid", "agg_ticker");
      }
      return urlObj.href;
    } catch (_) {
      return url;
    }
  }, []);

  return (
    <Box
      onPointerEnter={() => setOpenTooltip(true)}
      onPointerLeave={() => setOpenTooltip(false)}
    >
      <Tooltip.Provider>
        <Tooltip.Root
          delayDuration={250}
          disableHoverableContent={false}
          onOpenChange={onTooltipOpen}
          open={openTooltip}
        >
          <Tooltip.Trigger>
            <TickerItem onClick={onClick}>
              <StyledAnchor
                href={assembleUrl(item.url)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SourceLabel>{item.source}</SourceLabel>
                <HeadlineLabel>{headline}</HeadlineLabel>
              </StyledAnchor>
            </TickerItem>
          </Tooltip.Trigger>
          <TickerItemTooltip side="bottom">
            <StyledAnchor
              href={assembleUrl(item.url)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledCard>
                {showImage && imageLoaded && (
                  <StyledImage
                    alt={headline}
                    src={
                      fallbackImageLoaded
                        ? imageUrl
                        : getResizedUrl(imageUrl, { w: 164, h: 164 })
                    }
                  />
                )}
                <StyledTextContainer>
                  <FlexColumn css={{ gap: "$050" }}>
                    <StyledTopLine>
                      <StyledSource>{item.source}</StyledSource>
                      {timestamp && (
                        <StyledTimestamp>{timestamp}</StyledTimestamp>
                      )}
                    </StyledTopLine>
                    <StyledDescription showImage={showImage}>
                      {description}
                    </StyledDescription>
                  </FlexColumn>
                  {urlObject && (
                    <StyledSourceUrl>
                      {urlObject.hostname.replace("www.", "")}
                    </StyledSourceUrl>
                  )}
                </StyledTextContainer>
              </StyledCard>
            </StyledAnchor>
          </TickerItemTooltip>
        </Tooltip.Root>
      </Tooltip.Provider>
    </Box>
  );
};

NewsTickerItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    source: PropTypes.string,
    headline: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    date_created: PropTypes.string,
    image: PropTypes.string,
    og_description: PropTypes.string,
    og_image: PropTypes.string,
    og_title: PropTypes.string
  }),
  showImage: PropTypes.bool
};
