import { useState, useEffect, createContext, useContext } from "react";
import PropTypes from "prop-types";

const defaultValue = {};

const BreakpointContext = createContext(defaultValue);

const BreakpointProvider = ({ children, queries, isMobile }) => {
  const [queryMatch, setQueryMatch] = useState(
    isMobile
      ? { bp: "xs", xs: true, mx: false }
      : { bp: "mx", xs: false, mx: true }
  );

  useEffect(() => {
    const mediaQueryLists = {};
    const keys = Object.keys(queries);
    let isAttached = false;

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce((acc, media) => {
        acc[media] = !!(
          mediaQueryLists[media] && mediaQueryLists[media].matches
        );
        return acc;
      }, {});
      setQueryMatch(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches = {};
      keys.forEach((media) => {
        if (typeof queries[media] === "string") {
          mediaQueryLists[media] = window.matchMedia(queries[media]);
          matches[media] = mediaQueryLists[media].matches;
        } else {
          matches[media] = false;
        }
      });
      setQueryMatch(matches);
      isAttached = true;
      keys.forEach((media) => {
        if (typeof queries[media] === "string") {
          mediaQueryLists[media].addListener(handleQueryListener);
        }
      });
    }

    return () => {
      if (isAttached) {
        keys.forEach((media) => {
          if (typeof queries[media] === "string") {
            mediaQueryLists[media].removeListener(handleQueryListener);
          }
        });
      }
    };
  }, [queries]);

  return (
    <BreakpointContext.Provider value={queryMatch}>
      {children}
    </BreakpointContext.Provider>
  );
};

function useBreakpoints() {
  const context = useContext(BreakpointContext);
  /*
  if(context === defaultValue) {
    throw new Error("useBreakpoints must be used within BreakpointProvider");
  }
  */
  const bp = Object.keys(context).reduce((acc, key) => {
    if (context[key]) acc = key;
    return acc;
  }, "");
  return {
    bp,
    ...context
  };
}

BreakpointProvider.propTypes = {
  children: PropTypes.node,
  queries: PropTypes.object,
  isMobile: PropTypes.bool
};

export { BreakpointProvider, useBreakpoints };
