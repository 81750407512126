import PropTypes from "prop-types";
import { theme, styled } from "@washingtonpost/wpds-ui-kit";

// utils
import { getTimestampInfo } from "~/shared-components/story-card/_utilities/data";

const ONE_MONTH = 1000 * 60 * 60 * 24 * 30;

const StyledTimestamp = styled("span", {
  variants: {
    sigline: {
      true: {
        "&::before": {
          verticalAlign: "middle",
          color: theme.colors.gray80,
          content: "\u2022",
          margin: "0 $025"
        }
      }
    }
  }
});

export const Timestamp = ({ time, beforeTime, isSigline }) => {
  const { ts } = getTimestampInfo({
    time,
    thresholdInHours: ONE_MONTH,
    beforeTime,
    beforeText: time && beforeTime ? "Updated " : ""
  });

  return (
    <StyledTimestamp suppressHydrationWarning sigline={isSigline}>
      {ts}
    </StyledTimestamp>
  );
};

Timestamp.propTypes = {
  time: PropTypes.string,
  beforeTime: PropTypes.string,
  isSigline: PropTypes.bool
};
