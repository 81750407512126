import { styled, theme } from "@washingtonpost/wpds-ui-kit";
import PropTypes from "prop-types";
import Content from "./Content";
import AskThePostAi from "./AskThePostAi";
import Feedback from "./Feedback";

/**
 * The sections of content in the Catch-Up Module.
 *
 * Provides some styling and rearrangement to pull out ATPAI.
 */
const Sections = ({ sections }) => {
  const WithATPAI = styled("div", {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
    gap: theme.space[175],
    padding: `0 0 ${theme.space[225]}`
  });
  const MainSections = styled("div", {
    height: "auto",
    width: "100%",
    padding: 0,
    margin: "auto",
    alignSelf: "stretch",
    flexGrow: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
    gap: theme.space[100],
    fontWeight: theme.fontWeights.light,
    fontSize: theme.fontSizes["087"],
    section: {
      alignSelf: "stretch",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "stretch",
      gap: theme.space["050"],
      padding: 0
    }
  });
  const atpai = sections.pop() || {};
  const last = sections.pop() || {};

  return (
    <WithATPAI>
      <MainSections>
        {sections.map((section, i) => (
          <Content key={i} element={section} />
        ))}
        <Content element={last}>
          <Feedback />
        </Content>
      </MainSections>
      <AskThePostAi section={atpai} />
    </WithATPAI>
  );
};
Sections.propTypes = {
  sections: PropTypes.array
};

export default Sections;
