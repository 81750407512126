import PropTypes from "prop-types";

/* Utilities */
import get from "lodash.get";
import { fetchProps, getTimestampInfo } from "../_utilities/data";
import { isSecondaryLabelAsBylinePossible } from "../_utilities/helpers";
import { isHTMLFragment } from "./CustomHTML.helpers";
import { FLEX_FIELD_DEFAULTS } from "~/components/features/fronts/flex-feature/utilities/custom-field-defaults";

export const getTimeToRead = ({ content } = {}) => {
  const timeToRead = get(
    content,
    "additional_properties.time_to_read",
    undefined
  );
  const match = /(?:<\s?)?(.*)\s(min|h(ou)?r)(ute)?s?$/i.exec(timeToRead);
  return match ? `${match[1]} ${match[2]} read`.toLowerCase() : undefined;
};

export const joinAuthors = (authors = []) => {
  if (!authors || authors.length === 0) return "";
  if (authors.length === 1) return authors[0];
  return `${authors.slice(0, -1).join(", ")} and ${authors.slice(-1)}`;
};

const roleToPrefixMap = {
  audio_narrators: "Narrated by",
  audio_producers: "Produced by"
};

const k1 = "additional_properties.original.byline";
const k2 = "name";
/**
 * Get the author text
 * @param {object} author The author object
 * @returns {string} The author byline
 */
const getAuthorText = (author) => get(author, k1, get(author, k2, ""));

/**
 * Get the author text
 * @param {object} author The author object
 * @returns {string} The author byline but with anchor links
 */
const getAuthorRichText = (author) => {
  const { url } = author;
  const name = get(author, k1, get(author, k2, ""));
  return url ? `<a href="${url}">${name}</a>` : name;
};

/**
 * Get list of author objects
 * @param {Object} content  - content from which to harvest authors
 * @param {String} role  - author role, defaults to 'by'
 * @returns  {Array} - array of authors with name and image (extend as necessary)
 */
export const getAuthorObjects = ({ content = {}, role = "by" }) => {
  const authors = get(content, `credits.${role}`, []);
  if (!authors.length) return authors;
  return authors
    .map((author, i) => {
      const { url } = author;
      const name = getAuthorText(author);
      let image;
      if (i === 0) {
        image = get(content, "fusion_additions.headshot.url", image);
      }
      return name && { name, url, image };
    })
    .filter(Boolean);
};

/**
 * Get the byline text
 * @param {Object} content - content from which to harvest authors
 * @param {String} role - the role needed to harvest credits
 * @param {boolean} suppressPrefix - whether to suppress the prefix
 * @param {boolean} richText - whether to generate the text or richText which has author links
 * @returns {string} text of the byline
 */
export const getBylineText = ({
  content = {},
  role = "by",
  prefix,
  suppressPrefix = false,
  richText = false
}) => {
  const credits = get(content, "credits");
  if (!credits) return "";
  if (!prefix) prefix = roleToPrefixMap[role] || "By";
  if (suppressPrefix) prefix = "";
  const authors = getAuthorObjects({ content, role })
    .map((author) =>
      richText ? getAuthorRichText(author) : getAuthorText(author)
    )
    .filter((_) => _)
    .map((_) => _.trim());
  if (authors.length) return `${prefix} ${joinAuthors(authors)}`;
  return "";
};

export const getCompoundBylineText = ({ content, roles = [] }) => {
  if (!roles.length) return "";
  return roles
    .reduce((acc, role) => {
      const text = getBylineText({ content, role });
      if (text) acc.push(`${text}.`);
      return acc;
    }, [])
    .join(" ")
    .replace(/\.\./g, ".");
};

/**
 * Get the role. Used to harvest credits.
 * @param {Object} content - content from which to harvest authors
 * @returns {string} the role
 */
const getRole = ({ content }) =>
  content?.subtype === "audio" ? "host_talent" : "by";

/**
 * Get the byline text suitable for appearing in a label
 * @param {Object} content - content from which to harvest authors
 * @param {boolean} richText - whether to generate the text or richText which has author links
 * @returns {string} text of the byline
 */
export const getBylineLabelText = ({ content, richText = false }) =>
  getBylineText({
    content,
    role: getRole({ content }),
    suppressPrefix: true,
    richText
  });

export const getSeparator = ({
  items,
  i,
  separator = ", ",
  lastSeparator = " and "
}) => {
  const isLastItem = items.length - i === 1;
  let activeSeparator = "";
  if (i === items.length - 2) {
    activeSeparator = lastSeparator;
  } else if (!isLastItem && items.length > 2) {
    activeSeparator = separator;
  }
  return activeSeparator;
};

getSeparator.propTypes = {
  items: PropTypes.list,
  i: PropTypes.number,
  separator: PropTypes.string,
  lasSeparator: PropTypes.string
};

const getUseAltByline = ({ overrides }) => {
  const {
    bylineShow = true,
    bylineUseAlternate = false,
    thereIsBackingContent = true
  } = overrides;

  return thereIsBackingContent ? bylineUseAlternate : bylineShow;
};

getUseAltByline.propTypes = {
  overrides: PropTypes.object
};

const getAltByline = ({ content, overrides, isAdmin }) => {
  const bylineUseAlternate = getUseAltByline({ overrides });

  const defaultValue = "Write byline here";

  if (!bylineUseAlternate)
    return {
      altByline: {
        text: undefined
      },
      authors: undefined,
      timestamp: undefined
    };

  let { text } = fetchProps({
    data: content,
    keys: {
      text: ["feature_byline", "altByline"]
    },
    overrides
  });
  text = !isAdmin && text === defaultValue ? undefined : text;

  const fallbackValue = isAdmin ? defaultValue : undefined;

  return {
    altByline: {
      text:
        (!isAdmin && text && text !== defaultValue) || text
          ? text
          : fallbackValue,
      editableContentInfo: {
        defaultValue
      }
    }
  };
};

getAltByline.propTypes = {
  content: PropTypes.object,
  overrides: PropTypes.object,
  isAdmin: PropTypes.bool
};

export const getSiglinePrefix = (content, authors, showOpinion) => {
  let prefix = "By ";

  const isLettersToEditor =
    get(authors, "[0].name") === "Letters to the Editor";
  if (isLettersToEditor) return "";

  if (showOpinion) {
    prefix = get(content, "label.transparency.text", "").match(
      /Opinion|Perspective/
    )
      ? "Opinion by "
      : prefix;
  }

  const isEditorialBoard = /editorial board/i.test(
    get(authors, "[0].name", "")
  );

  prefix = `${prefix}${
    isEditorialBoard ? prefix.replace(/^.*by\s+$/i, "the ") : ""
  }`;

  return prefix;
};

const formatToDateFormat = {
  "EEEE, MMMM d, 'at' h aaaa z": "extended",
  "EEEE, MMMM d, 'at' h:mm aaaa z": "extended-with-minutes",
  "MMMM d": "month-day"
};

/**
 * @desc Whether or not the secondary label should be forced on
 * @param {object} content The content object
 * @param {object} overrides The custom fields object
 * @param {string} outputType The outputType.
 * @returns {bool}
 */
const suppressByline = ({ content, overrides, outputType }) => {
  const {
    siglineHide = FLEX_FIELD_DEFAULTS.siglineHide,
    bylineShow = FLEX_FIELD_DEFAULTS.bylineShow,
    labelSecondaryShow = FLEX_FIELD_DEFAULTS.labelSecondaryShow
    // labelSecondary = FLEX_FIELD_DEFAULTS.labelSecondary
  } = overrides;

  // NOTE: "Transparency" for placeholder content
  const isPlaceholder = /^Transparency$/i.test(
    content?.label?.transparency?.text
  );

  if (siglineHide || (/jsonapp/.test(outputType) && !isPlaceholder))
    return true;

  const show = bylineShow || getUseAltByline({ overrides });

  if (
    isPlaceholder ||
    !show || // if it's not due to show
    !labelSecondaryShow // only eligible to be off if secondary label is on
    // TODO: The decision to remove this may be revisited. Undo as necessary
    // (labelSecondaryShow && labelSecondary) // only eligible to be off if secondary label is on
  )
    return !show;

  return isSecondaryLabelAsBylinePossible({ content });
};

const getShowActions = ({ outputType, overrides }) => {
  if (!/jsonapp/.test(outputType)) return false;

  const {
    siglineHide = FLEX_FIELD_DEFAULTS.siglineHide,
    bylineShow = FLEX_FIELD_DEFAULTS.bylineShow,
    timestampShow = FLEX_FIELD_DEFAULTS.timestampShow,
    actionsShow = FLEX_FIELD_DEFAULTS.actionsShow
  } = overrides;

  if (/always/.test(actionsShow)) return true;
  if (/never/.test(actionsShow)) return false;
  if (/if-sigline-show/.test(actionsShow))
    return siglineHide ? false : !!bylineShow || !!timestampShow;

  return false;
};

export const getSigline = ({
  content = {},
  overrides = {},
  outputType,
  isAdmin = false
}) => {
  const {
    siglineHide = FLEX_FIELD_DEFAULTS.siglineHide,
    bylineShow = FLEX_FIELD_DEFAULTS.bylineShow,
    bylineShowOpinion = FLEX_FIELD_DEFAULTS.bylineShowOpinion,
    timestampShow = FLEX_FIELD_DEFAULTS.timestampShow,
    timestamp: ms = FLEX_FIELD_DEFAULTS.timestamp,
    timestampDuration = FLEX_FIELD_DEFAULTS.timestampDuration,
    timestampRecencyThreshold = FLEX_FIELD_DEFAULTS.timestampRecencyThreshold,
    timestampKey = "display_date",
    timestampBeforeKey = "additional_properties.first_display_date",
    timestampUseFixedDateFormat = FLEX_FIELD_DEFAULTS.timestampUseFixedDateFormat,
    timestampFixedDateFormat = FLEX_FIELD_DEFAULTS.timestampFixedDateFormat
  } = overrides || {};
  content = content || {};

  // NOTE: Complicated by whether thereIsBackingContent
  let bylineUseAlternate = getUseAltByline({ overrides });

  const showActions = getShowActions({ outputType, overrides });

  if (
    siglineHide ||
    (!bylineShow && !bylineUseAlternate && !timestampShow && !showActions)
  ) {
    return undefined;
  }

  const authorKeys = [`credits.${getRole({ content })}`];
  const {
    authors = undefined,
    isoDateFromContent,
    isoBeforeDateFromContent
  } = fetchProps({
    data: content,
    keys: {
      authors: authorKeys,
      isoDateFromContent: [timestampKey, ""],
      isoBeforeDateFromContent: [timestampBeforeKey, ""]
    },
    overrides
  });

  if (authors) {
    authors.map((author) => {
      author.name =
        get(author, "additional_properties.original.byline") || author.name;
      delete author.additional_properties;
      return author;
    });
  }

  const { altByline } = getAltByline({
    content,
    overrides,
    isAdmin
  });

  // NOTE: Do we need a polyfill? If so, see: https://mzl.la/33NuU3x
  const isoDateFromCustomField = ms ? new Date(ms).toISOString() : undefined;

  // NOTE: Ahh, relief, got the date to use!
  const isoDateToUse = isoDateFromCustomField || isoDateFromContent;

  const UPDATED = "Updated ";

  const { ts, diffInMins } = getTimestampInfo({
    time: isoDateToUse,
    thresholdInHours: timestampDuration,
    format: timestampUseFixedDateFormat ? timestampFixedDateFormat : undefined,
    beforeText: isoDateToUse && isoBeforeDateFromContent ? UPDATED : "",
    beforeTime: isoBeforeDateFromContent
  });

  const isUpdated = !!(ts && ts.match(UPDATED));

  const okToShowTimestamp =
    isoDateToUse &&
    timestampShow &&
    !Number.isNaN(diffInMins) &&
    (diffInMins < 0 || diffInMins < timestampDuration * 60);

  // In some feeds we have authors but bylineUseAlternate is undefined
  // because there is no backing content. In that case we want to fallback
  // to using the authors if we have them
  if (
    authors &&
    !overrides.bylineUseAlternate &&
    bylineUseAlternate &&
    bylineUseAlternate.text === undefined
  ) {
    bylineUseAlternate = false;
  }

  if (
    !showActions &&
    !okToShowTimestamp &&
    ((!bylineUseAlternate && !authors) ||
      (bylineUseAlternate && !altByline.text))
  ) {
    return undefined;
  }

  let dateFormat;
  let format;
  if (okToShowTimestamp && timestampUseFixedDateFormat) {
    format = timestampFixedDateFormat;
    dateFormat = formatToDateFormat[format];
  }

  // NOTE: For now, only show timeToRead if timestamp is configured to show
  // but the timestamp is not ok to show.
  const timeToRead =
    timestampShow && !okToShowTimestamp
      ? getTimeToRead({ content })
      : undefined;

  const bylineInfo = suppressByline({ content, overrides, outputType })
    ? {}
    : {
        altByline: bylineUseAlternate ? altByline : undefined,
        authors: bylineUseAlternate || !bylineShow ? undefined : authors,
        prefix: getSiglinePrefix(content, authors, bylineShowOpinion)
      };

  return {
    ...bylineInfo,
    timestamp: okToShowTimestamp ? ts : undefined,
    diffInMins,
    recencyThreshold: okToShowTimestamp ? timestampRecencyThreshold : undefined,
    isoDate: okToShowTimestamp ? isoDateToUse : undefined,
    dateFormat,
    format,
    isUpdated,
    timeToRead,
    showActions
  };
};

getSigline.propTypes = {
  content: PropTypes.object,
  overrides: PropTypes.object,
  isAdmin: PropTypes.bool
};

export const groomSiglineForJsonApp = ({ sigline }) => {
  if (!sigline) {
    return undefined;
  }

  const {
    authors,
    altByline,
    isoDate,
    dateFormat,
    format,
    prefix,
    recencyThreshold,
    isUpdated,
    timeToRead,
    showActions
  } = sigline;

  let byline;

  if (altByline) {
    byline = get(altByline, "text");
  } else if (authors) {
    byline = authors
      .map((author, index) => {
        return `${index === 0 ? prefix : ""}${
          author.name || author.org
        }${getSeparator({
          items: authors,
          i: index
        })}`;
      })
      .join("");
  }

  if (byline || isoDate || timeToRead || showActions) {
    return {
      ...(byline
        ? {
            by_line: byline,
            mime: isHTMLFragment(byline) ? "text/html" : undefined
          }
        : {}),
      ...(isoDate
        ? {
            timestamp: isoDate,
            dateFormat,
            format,
            recencyThreshold,
            ...(isUpdated ? { isUpdated } : {})
          }
        : {}),
      ...(timeToRead ? { timeToRead } : {}),
      ...(showActions ? { showActions } : {})
    };
  }
  return undefined;
};

groomSiglineForJsonApp.propTypes = {
  sigline: PropTypes.object
};
