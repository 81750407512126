import { ContentPropTypes } from "~/proptypes/catch-up-module";
import List from "./List";
import Header from "./Header";

/**
 * Generic content wrapper component for prism-content-api data.
 *
 * Parses content into Sections, Headers, Texts, and Lists.
 */
const Content = ({ element, children }) => {
  const Section = ({ element: _element, children: _children }) => {
    const { items, "aria-labelledby": ariaLabelledBy } = _element;
    return (
      <section aria-labelledby={ariaLabelledBy}>
        {items.map((e, i) => (
          <Content key={i} element={e} />
        ))}
        {_children}
      </section>
    );
  };
  Section.propTypes = Content.propTypes;
  const Text = ({ element: _element }) => {
    const { content, _id } = _element;
    return <span id={_id} dangerouslySetInnerHTML={{ __html: content }} />;
  };
  Text.propTypes = Content.propTypes;
  const typeToComponent = {
    section: Section,
    list: List,
    header: Header,
    text: Text
  };

  const type = element?.type;
  const Component = typeToComponent[type];

  return Component ? <Component element={element}>{children}</Component> : null;
};
Content.propTypes = ContentPropTypes;

export default Content;
