import {
  getLocalStorageItem,
  setLocalStorageItem
} from "@washingtonpost/subs-sdk";

const LS_EXCLUSIONS_NAME = `agg_ticker_exclusions`;
const LS_AGGR_TICKER_VISIBILITY_NAME = `agg_ticker_visibility`;
export const TICKER_HEIGHT = 48;
export const TICKER_FONT_SIZE = "14px !important";
export const TICKER_TITLE = "Beyond The Post";
export const TICKER_SPEED = 6;
export const SHOW_TITLE = false;

export const getLocalStorageExclusions = () => {
  const localStorageExclusions =
    getLocalStorageItem(LS_EXCLUSIONS_NAME) || "[]";
  return JSON.parse(localStorageExclusions);
};

export const setLocalStorageExclusions = (ids = []) => {
  const currentValue = getLocalStorageExclusions();
  const newVal = Array.from(new Set([...currentValue, ...ids]));
  setLocalStorageItem(LS_EXCLUSIONS_NAME, JSON.stringify(newVal));
};

export const getAggTickerVisibility = () => {
  const storedValue =
    getLocalStorageItem(LS_AGGR_TICKER_VISIBILITY_NAME) || "true";
  return storedValue === "true";
};

export const setAggTickerVisibility = (value) => {
  setLocalStorageItem(LS_AGGR_TICKER_VISIBILITY_NAME, value);
};

export const orderTickerItemsBySource = (tickerItemsBySource = {}) => {
  let keyIndex = 0;
  const mutableTickerItems = structuredClone(tickerItemsBySource);
  const serializedData = [];
  const sources = Object.keys(mutableTickerItems);

  // We want to show one story per source while we have them
  while (Object.values(mutableTickerItems).find((arr) => arr.length > 0)) {
    const currentSource = sources[keyIndex];
    const nextItem = mutableTickerItems[currentSource].shift();
    if (nextItem) {
      serializedData.push(nextItem);
    }
    keyIndex = keyIndex + 1 > sources.length - 1 ? 0 : keyIndex + 1;
  }
  return serializedData;
};
