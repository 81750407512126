import PropTypes from "prop-types";
import get from "lodash.get";
import { getClasses } from "@washingtonpost/front-end-utils";
import { styled, theme } from "@washingtonpost/wpds-ui-kit";

const StyledPlaylistWrapper = styled("div", {
  color: theme.colors["gray700-static"]
});

const PlayListItem = (props) => {
  const videoLabel = get(props, "videoData.videoLabel", "");
  const [, videoNum = 2] = videoLabel?.split(" ");

  return (
    <div
      className={`${getClasses(
        `live-playlist-item live-playlist-item-${videoNum} ${props.playlistItemClasses}`,
        {
          "justify-center": props.isCentered,
          "headline-divider": props.hasDivider,
          "third-item-padding": props.needsPadding
        }
      )}`}
      onClick={() => props.handlePlaylistClick(videoLabel)}
    >
      <span>
        <strong>{get(props, "videoData.headlinePrefix")}</strong>{" "}
        {get(props, "videoData.headline")}
        {props.isAdmin && (
          <span className="red italic pl-xs">[{videoLabel}]</span>
        )}
      </span>
    </div>
  );
};

PlayListItem.propTypes = {
  playlistItemClasses: PropTypes.string,
  isCentered: PropTypes.bool,
  hasDivider: PropTypes.bool,
  needsPadding: PropTypes.bool,
  isAdmin: PropTypes.bool,
  handlePlaylistClick: PropTypes.func
};

const PlaylistItems = (props) => {
  const {
    isAdmin,
    className = "",
    legitVids,
    handlePlaylistClick,
    videoSlot2Props,
    videoSlot3Props
  } = props;

  const playlistItemClasses = "flex items-center pointer z-1 pt-sm pb-sm";

  return (
    <div className="dn-s live-playlist-container">
      <StyledPlaylistWrapper
        className={`${getClasses(
          `live-playlist-wrapper items-center pt-sm pb-sm ma-auto ${className}`,
          {
            multiple: legitVids.length === 3
          }
        )}`}
      >
        <PlayListItem
          playlistItemClasses={playlistItemClasses}
          isAdmin={isAdmin}
          handlePlaylistClick={handlePlaylistClick}
          {...videoSlot2Props}
          hasDivider={legitVids.length === 3}
        />
        {legitVids.length === 3 && (
          <PlayListItem
            playlistItemClasses={playlistItemClasses}
            isAdmin={isAdmin}
            handlePlaylistClick={handlePlaylistClick}
            needsPadding
            {...videoSlot3Props}
          />
        )}
      </StyledPlaylistWrapper>
    </div>
  );
};

PlaylistItems.propTypes = {
  isAdmin: PropTypes.bool,
  className: PropTypes.string,
  definedData: PropTypes.object,
  handlePlaylistClick: PropTypes.func,
  legitVids: PropTypes.arrayOf(PropTypes.object),
  videoSlot2Props: PropTypes.shape({
    headlinePrefix: PropTypes.any,
    alternateArt: PropTypes.string,
    headline: PropTypes.string,
    videoData: PropTypes.object,
    videoLabel: PropTypes.string
  }),
  videoSlot3Props: PropTypes.shape({
    headlinePrefix: PropTypes.any,
    alternateArt: PropTypes.string,
    headline: PropTypes.string,
    videoData: PropTypes.object,
    videoLabel: PropTypes.string
  })
};

export default PlaylistItems;
