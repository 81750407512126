/* eslint-disable no-underscore-dangle */
/**
 * Permutive is a system for user tracking used by ads.
 * This code is meant to be kept consistent between Spectrum and Assembler
 * The canonical version is maintained here:
 * https://github.com/WashPost/permutive-onsite
 *
 * Spectrum's copy is here:
 * https://github.com/WashPost/spectrum/blob/main/components/utilities/permutive.js
 *
 */

import { loadPreviousSegments } from "./permutive-external";
import logger from "~/spartan-homepage/logger";

// this is our public api key
export const PERMUTIVE_API_KEY = "b1345a23-4810-46bd-a111-1ae9732b6d57";
// workspace and organization ids are the same
export const PERMUTIVE_WORKSPACE_ID = "5f1f2661-22d4-401c-9c10-4d7647fb6edc";
export const PERMUTIVE_ORGANIZATION_ID = "5f1f2661-22d4-401c-9c10-4d7647fb6edc";
export const PERMUTIVE_SETTINGS = { consentRequired: true };

/** *******************
 * Duplicated; in Spectrum this replaces these imports:
 * 
import getCookie from "./cookies";
import { getLoginDetails } from "./login-details";
import { getJucid } from "./analytics/clavisService";
 */

const getCookie = (name) => {
  if (typeof window === "undefined") return undefined;
  const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return v ? v[2] : null;
};

const getLoginDetails = () => {
  return {
    wapoSecureID: getCookie("wapo_secure_login_id"),
    wapoLoginID: getCookie("wapo_login_id"),
    userAgent:
      typeof window !== "undefined" ? window.navigator.userAgent : undefined
  };
};

/**
 * @func getJucid
 * @desc retrieve a UUID from localStorage; this has been renamed to j_ucid in most other applications
 *   if there is none, generate one save it to localStorage and return the value
 * @return {String}
 */
export function getJucid() {
  let jUcid = localStorage.getItem("uuid");
  if (!jUcid) {
    jUcid = crypto.randomUUID(); // https://codeql.github.com/codeql-query-help/javascript/js-insecure-randomness/
    localStorage.setItem("uuid", jUcid);
  }
  return jUcid;
}

/// ////////////////////////////////////

/**
 * @desc This is the portion of the permutive loading snippet that
 * can operate outside of the check for consent. Extracted from
 * https://developer.permutive.com/docs/web
 *
 * Behavior:
 * - Mocks the API of a window.permutive object that can be used while the script loads
 */

export const permutiveSetup = () => {
  return {
    __html: `
      !function(e,o,n,i){if(!e){e=e||{},window.permutive=e,e.q=[];var t=function(){return([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,function(e){return(e^(window.crypto||window.msCrypto).getRandomValues(new Uint8Array(1))[0]&15>>e/4).toString(16)})};e.config=i||{},e.config.apiKey=o,e.config.workspaceId=n,e.config.environment=e.config.environment||"production",(window.crypto||window.msCrypto)&&(e.config.viewId=t());for(var g=["addon","identify","track","trigger","query","segment","segments","ready","on","once","user","consent"],r=0;r<g.length;r++){var w=g[r];e[w]=function(o){return function(){var n=Array.prototype.slice.call(arguments,0);e.q.push({functionName:o,arguments:n})}}(w)}}}(window.permutive,"${PERMUTIVE_API_KEY}","${PERMUTIVE_WORKSPACE_ID}",${JSON.stringify(
      PERMUTIVE_SETTINGS
    )});
      `
  };
};

export const generatePermutiveSetup = () => {
  return (
    <script
      type="text/javascript"
      dangerouslySetInnerHTML={permutiveSetup()}
      key="thidpartyscript-permutivesetup"
      data-testid="thidpartyscript-permutivesetup"
    />
  );
};

/**
 *
 * @param {str} str a string to log to the console
 * @desc Utility for easier testing that appends `permutive` before a log string
 * only fires if the ?debug=true parameter exists
 * (this is consistent with the consent api debug behavior)
 */
function logit(str) {
  const isDebug =
    (window &&
      window.location &&
      window.location.search.indexOf("debug=true") > -1) ||
    false;
  if (isDebug) {
    logger.info(`permutive: ${str}`);
  }
}

/**
 * @desc Users in the European Economic Area require different consent
 * @returns Boolean
 */
function isEEA() {
  const wpGeo = getCookie("wp_geo");
  logit(`wpGeo: ${wpGeo}`);
  if (!wpGeo) {
    return false;
  }
  return /\|EEA/.test(wpGeo);
}

/**
 * @desc execute the permutive.identify call with appropriate IDs.
 */
function sendIdentity() {
  try {
    const loginId = getLoginDetails().wapoLoginID;
    const jUcid = getJucid();

    const identities = [];

    // loginID is null if not found
    if (loginId) {
      identities.push({
        // lower for consistency to back end systems
        id: loginId.toLowerCase(),
        tag: "login_id",
        priority: 0
      });
    }

    // should always be found since getJucid() sets it when not found
    if (jUcid) {
      identities.push({
        // lower for consistency to back end systems
        id: jUcid.toLowerCase(),
        tag: "j_ucid",
        priority: 10 // allows space for intermediate priorities in the future if needed
      });
    }
    window.permutive.identify(identities);
    logit(`identify sent: ${JSON.stringify(identities)}`);
  } catch (err) {
    logit("ERROR: could not send identity to Permutive");
  }
}

/**
 * @desc Triggers all the permutive behaviors that occur after consent
 * This function assumes we have already waited for the pwapi
 * This function assumes we've received consent and turned on permutive
 */
function runPermutive() {
  if (typeof window !== "undefined") {
    loadPreviousSegments();
    sendIdentity();
    const metadata = window?.wpAdFusion?.permutive || {};
    // Register the pageview
    window.permutive.addon("web", { page: { ...metadata } });
    logit(`sent pageview with ${JSON.stringify(metadata)}`);
  }
}

/**
 * @desc Appends the permutive library to the head
 *
 * NOTE: we can't currently simply place this in the head because the Permutive
 * library does not block loading in the situation where a user has previously
 * fired a `window.permutive.consent({opt_in:true})` event in their browser. This
 * creates a race condition where Permutive can start executing tracking when it
 * shouldn't if the user later revokes consent.
 *
 * TODO this will delay running Permutive making it harder to beat the first ad call
 * We have a request to Permutive to see if there's a way to move it back to the head
 * TODO at that point, we can move this behavior into the `generatePermutiveSetup` function.
 */
function loadPermutiveLibrary() {
  const script = document.createElement("script");
  script.src = `https://${PERMUTIVE_ORGANIZATION_ID}.edge.permutive.app/${PERMUTIVE_WORKSPACE_ID}-web.js`;
  script.id = "script-permutiveload";
  document.head.appendChild(script);
}

/**
 * @desc Handles all Permutive behavior for the US Privacy API Consent scenario
 * This API handles consent requirements for e.g. California, Virginia and more to come.
 * @param {function} uspapi the function found at window.__uspapi
 */
export function processUspConsent(uspapi) {
  uspapi("getUSPData", 1, (uspData, success) => {
    if (success) {
      logit(`uspData.uspString: ${uspData.uspString}`);

      // eslint-disable-next-line max-len
      // How to use API response: https://arcpublishing.atlassian.net/wiki/spaces/WPDP/pages/3134390578/Validate+CCPA+Compliance+with+the+US+Privacy+API
      const optedOut = uspData.uspString.charAt(2);
      if (optedOut === "Y") {
        logit("User has a state of Opted Out = 'Y'");
        window.permutive.consent({ opt_in: false });

        // TODO: this will clear Permutive's localStorage object
        // so that previously calculated segments are deleted.
        // TODO uncomment this once Permutive's library solves race condition
        // (support ticket sent to Permutive)
        // NOTE: this behavior would be an extra safety mechanism, but currently
        // we wrap the use of permutive's localStorage in the consent check so we
        // are still compliant without this.
        // window.permutive.reset();
      } else if (optedOut === "N" || optedOut === "-") {
        logit(`User has consented with a state of Opted Out = ${optedOut}`);
        window.permutive.consent({
          opt_in: true,
          token: uspData.uspString
        });
        loadPermutiveLibrary();
        runPermutive();
      } else {
        logit(
          "ERROR: Unexpected response format from __uspapi. Permutive will not run."
        );
      }
    } else {
      logit("ERROR: failure of the __uspapi. Permutive will not run.");
    }
  });
}

/**
 * @desc Parses the tcf consents object. Current assumption is that if any purpose is "No Consent" we won't run
 * Docs: https://arcpublishing.atlassian.net/wiki/spaces/WPDP/pages/3040772115/How+to+Test+-+GDPR
 * @param {Object} consents Object from the tcfData.purpose.consents,
 * which has format of key: 'purposeId', value: Boolean
 * @returns Boolean
 */
export function hasUserConsentedTcf(consents) {
  if (Object.keys(consents).length === 0) {
    return false;
  }

  const listed = Object.keys(consents).map((k) => consents[k]);
  return listed.every((v) => v === true);
}

/**
 * Handles all Permutive behavior for the Transparency and Consent Framework API (consent in the European Union)
 * @param {function} tcfapi the function found at window.__tcfapi which processes EU / EEA consent
 */
export function processTcfConsent(tcfapi) {
  const version = 2; // always
  tcfapi("addEventListener", version, (tcData, success) => {
    if (!success) {
      logit(
        "ERROR: failed to register the __tcfapi event listener. Permutive will not run."
      );
      logit(`tcData: ${JSON.stringify(tcData)}`);
    } else {
      logit(`tcf callback triggered. eventStatus: ${tcData.eventStatus}`);
      logit(`tcf callback listenerId: ${tcData.listenerId}`);

      if (
        tcData.eventStatus === "tcloaded" ||
        tcData.eventStatus === "useractioncomplete"
      ) {
        logit(`consents = ${JSON.stringify(tcData?.purpose?.consents)}`);

        // remove listener so we don't get called more than once
        tcfapi(
          "removeEventListener",
          version,
          (removed) => {
            if (removed) {
              logit("tcfapi eventListener removed");
            } else {
              logit("tcfapi eventListener NOT removed");
            }
          },
          tcData.listenerId
        );

        const consents = tcData?.purpose?.consents;
        if (consents) {
          if (hasUserConsentedTcf(consents)) {
            logit(`User has consented with token ${tcData.tcString}`);
            window.permutive.consent({
              opt_in: true,
              token: tcData.tcString
            });
            loadPermutiveLibrary();
            runPermutive();
          } else {
            window.permutive.consent({ opt_in: false });
            // TODO uncomment this once Permutive's library allows
            // window.permutive.reset();
          }
        } else {
          logit("tcData.purpose.consents not found. Permutive will not run");
        }
      }
    }
  });
}

/**
 * @func permutiveTrigger
 * @desc Triggers execution of Permutive, which is used for ad targeting.
 */
function permutiveTrigger() {
  if (typeof window !== "undefined") {
    const eu = isEEA();
    if (eu) {
      if (window.__tcfapi) {
        processTcfConsent(window.__tcfapi);
      } else {
        logit("__tcfapi is missing. Permutive will not run");
      }
    } else if (window.__uspapi) {
      processUspConsent(window.__uspapi);
    } else {
      logit("__uspapi is missing. Permutive will not run.");
    }
  }
}

export default permutiveTrigger;
