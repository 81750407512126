// import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { createContext, useContext } from "react";
import PropTypes from "prop-types";
// import { useAppContext } from "fusion-context";
// import getEnv from "~/components/utilities/env";
// import forYouCategorical from "~/content/sources/foryou-flex-categorical";

// const env = getEnv();

const PersonalizedContentContext = createContext({});

/**
 *
 * Provide personalized content and helpers. This is what's available in the context:
 * {
 *   isReady -- set to true once the fetch to the foryou /categorical endpoint goes to LOADING
 *    downstream code checks if SUCCESS or ERROR
 *
 *   headlinesBySurface -- The results in an object structured by what useApi (via useHook) returns, i.e.
 *     {
 *       state -- LOADING | ERROR | SUCCESS
 *       error -- undefined or object if state is ERROR
 *       content -- undefined or object if state is SUCCESS
 *         content will have:
 *         - items (list of content suitale for rendering by the flex feature)
 *         - other foryou info
 *     }
 *
 *   registerForYouSurface -- a function whereby features that want to use personalized
 *     content register their surface and number of items they will render
 * }
 *
 */
function PersonalizedContentProvider({ children }) {
  // const { isAdmin } = useAppContext();
  // const [isReady, setReady] = useState(false);

  // TODO: Investigate why adding isReady in the dependency array makes the code better-behaved
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const rawModules = useMemo(() => [], [isReady]);
  // NOTE: With rawModules initialized this way, content renders in the admin, however, the code
  // goes into an infinite loop. Argh!
  // const rawModules = [];
  // const [modules, setModules] = useState([]);

  /**
   *
   * For each registration, an entry is created in rawModules structured like:
   * {
   *   id -- the id of the feature making the registration
   *   limit -- the number of items the feature is configured to show
   *   surface -- the surface, which is a foryou concept like an id
   * }
   *
   */
  /*
  const registerForYouSurface = ({
    id,
    customFields,
    contentConfig,
    surface
  }) => {
    const contentService = contentConfig?.contentService;
    if (/foryou-flex-headlines-by-surface/.test(contentService)) {
      let limitConfig = contentConfig.contentConfigValues?.limit;
      let { limit, limitMobileOnly } = customFields;

      limitConfig = /^\d+$/.test(limitConfig)
        ? Number.parseInt(limitConfig)
        : 0;
      limit = /^\d+$/.test(limit) ? Number.parseInt(limit) : 0;
      limitMobileOnly = /^\d+$/.test(limitMobileOnly)
        ? Number.parseInt(limitMobileOnly)
        : 0;

      let limitToUse = Math.max(limit, limitMobileOnly);
      limitToUse = limitToUse > 0 ? limitToUse : limitConfig;

      if (limitToUse > 0) {
        const module = {
          id,
          limit: limitToUse,
          surface
        };
        rawModules.push(module);
      }
    }
    return null;
  };
  */

  /**
   * The following code takes rawModules, aggregates across surfaces, and sets moduless
   * as a list of items each structrued like the object below. The /categorical endpoint
   * requires this format
   * {
   *   surface: "string_identifying_which_module",
   *   article_count: 8, // num articles requested
   * }
   */
  /*
  useEffect(() => {
    const modulesBySurface = rawModules.reduce(
      (acc, { surface, id, limit }) => {
        if (!acc[surface]) acc[surface] = {};
        acc[surface] = {
          ...acc[surface],
          [id]: limit
        };
        return acc;
      },
      {}
    );

    const modulesToUse = Object.keys(modulesBySurface).reduce(
      (acc, surface) => {
        acc.push({
          surface,
          article_count: Object.keys(modulesBySurface[surface]).reduce(
            (total, id) => {
              return total + modulesBySurface[surface][id];
            },
            0
          )
        });
        return acc;
      },
      []
    );

    setModules(modulesToUse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
  }, [rawModules]);
  */

  // NOTE: useHook calls the /categorical endpoint (using the useApi hook)
  /*
  const headlinesBySurface = forYouCategorical.useHook(
    { modules, env },
    modules.length || isAdmin
  );
  */

  /*
  const findHeadlinesForSurface = (query) => {
    return {
      state: headlinesBySurface.state,
      error: headlinesBySurface.error,
      content: {
        ...headlinesBySurface?.content?.modules?.find(
          ({ surface }) => surface === query?.surface
        )
      }
    };
  };
  */

  /*
  useEffect(() => {
    setReady(/LOADING/.test(headlinesBySurface?.state));
  }, [headlinesBySurface?.state]);
  */

  return (
    <PersonalizedContentContext.Provider
      value={{
        isReady: false,
        headlinesBySurface: undefined,
        findHeadlinesForSurface: () => null,
        registerForYouSurface: () => null
      }}
    >
      {children}
    </PersonalizedContentContext.Provider>
  );
}

PersonalizedContentProvider.propTypes = {
  children: PropTypes.any
};

const { Consumer: PersonalizedContentConsumer } = PersonalizedContentContext;

function usePersonalizedContentContext() {
  return useContext(PersonalizedContentContext);
}

export {
  usePersonalizedContentContext,
  PersonalizedContentProvider,
  PersonalizedContentConsumer
};
