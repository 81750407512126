import presetGenerator from "./preset-generator";

const presets = {
  " ": {
    // NOTE: key is space on purpose
  },
  "Add blurb": {
    blurbHide: false
  },
  "Hide blurb": {
    blurbHide: true
  },
  "Add related link": {
    relatedLinksNum: 1
  },
  "Add related links — more coverage": {
    relatedLinksPosition: "Default",
    relatedLinksNum: 2,
    relatedLinksLabel: "MORE COVERAGE"
  },
  "Add related links — prominent": {
    relatedLinksPosition: "Default",
    relatedLinksNum: 1,
    relatedLinksNumMobileOnly: "",
    relatedLinksSize: "Small",
    relatedLinksLabel: "MORE COVERAGE"
  },
  "Add image right, large": {
    artHide: false,
    artPosition: "Art right of head",
    artSize: "Large",
    artAspectRatio: "3:2",
    captionContent: "None"
  },
  "Image position: Left": {
    artHide: false,
    artPosition: "Art left",
    artSize: "Large",
    captionContent: "None"
  },
  "Image aspect ratio: 16:9": {
    artAspectRatio: "16:9"
  },
  "Image aspect ratio: 3:2": {
    artAspectRatio: "3:2"
  },
  "Image aspect ratio: 1:1": {
    artAspectRatio: "1:1"
  },
  "Image: Author photo": {
    artImageFallback: "headshot",
    artHide: false,
    artPosition: "Art right",
    artSize: "X-Small",
    artAspectRatio: "1:1",
    artMakeItRound: "if-headshot"
  },
  "Hide image": {
    artHide: true
  },
  "Unhide image": {
    artHide: false
  },
  "Show art full width": {
    artHide: false,
    artPosition: "Art below byline"
  },
  "Lede-ify it -- Top Stories": {
    mobilePreset: "+ Image: Display (image above + caption/credit)",
    artAspectRatio: "3:2",
    headlineSize: "Extra large"
  },
  "Lede-ify it": {
    mobilePreset: "+ Image: Display (image above + caption/credit)",
    headlineHide: false,
    headlineSize: "Large",
    headlineStyle: "Bold"
  },
  "Center text": {
    textAlignment: "center",
    textVerticalAlignment: "center",
    wrapText: false
  },
  "Rule – Dark": {
    horizontalDividerWeight: "bold"
  },
  "Rule – Light": {
    horizontalDividerWeight: "normal"
  },
  "Medium headline": {
    headlineHide: false,
    headlineSize: "Medium"
  },
  "Standard headline": {
    headlineHide: false,
    headlineSize: "Standard"
  },
  "Ticker-ify it": {
    textAlignment: "left",
    labelShow: true,
    labelType: "Live Updates",
    labelPosition: "Above Headline",
    blurbHide: true,
    bylineShow: false,
    timestampShow: false,
    relatedLinksPosition: "Default",
    liveTickerHide: false,
    liveTickerNum: 2,
    liveTickerNumMobileOnly: 2,
    actionsShow: "never"
  },
  "Label -- Breaking News": {
    labelShow: true,
    labelType: "Live Updates",
    label: "Breaking News",
    labelPosition: "Above Headline"
  },
  "Label -- Election 2024": {
    labelShow: true,
    labelType: "Kicker",
    labelPosition: "Above Headline",
    label: "Election 2024",
    labelUrl: "https://www.washingtonpost.com/elections/election-2024/",
    labelIcon: "Election Icon"
  },
  "Label -- Live Updates": {
    labelShow: true,
    labelType: "Live Updates",
    labelPosition: "Above Headline"
  },
  "Label -- Small show 1° only": {
    labelShow: true,
    labelType: "Kicker",
    labelPosition: "Above Headline",
    labelSecondaryShow: false
  },
  "Label -- Small show 1° and 2°": {
    labelShow: true,
    labelType: "Kicker",
    labelPosition: "Above Headline",
    labelSecondaryShow: true
  },
  "Label -- Small show 2° only": {
    labelShow: false,
    labelType: "Kicker",
    labelPosition: "Above Headline",
    labelSecondaryShow: true
  },
  "Hide label": {
    labelShow: false,
    labelSecondaryShow: false
  },
  "Show byline": {
    bylineShow: true
  },
  "Hide byline": {
    bylineShow: false
  },
  "Video -- Autoplay on": {
    inlineVideo: true,
    artOverlayShow: true,
    autoplay: true,
    muted: true,
    ads: false
  },
  "Video -- Autoplay off": {
    autoplay: false,
    muted: false,
    ads: true
  },
  "The 7 (mobile)": {
    mobilePreset: "As is",
    labelShow: true,
    labelType: "Package Nested",
    label: "The 7",
    labelIcon: "The 7",
    headlineSize: "Large",
    blurbHide: false,
    descriptionText: "<b>What you need to know:</b> TEXT_HERE",
    bylineShow: false,
    timestampShow: false,
    ctaLabelShow: false,
    ctaLabelType: "CTA",
    ctaLabel: "Read or listen",
    ctaLabelSecondaryShow: true,
    ctaLabelSecondary: "3 min.",
    footnoteHide: true,
    footnoteAlignment: "right"
  },
  "Washington Post Live": {
    mobilePreset: "+ Image: Display – Hide blurb",
    labelShow: true,
    labelType: "Kicker",
    labelPosition: "Above Headline",
    label: "Washington Post Live",
    labelUrl: "https://www.washingtonpost.com/washington-post-live/",
    labelSecondaryShow: true,
    labelSecondary: "Video",
    captionContent: "None",
    inlineVideo: true,
    autoplay: true,
    muted: true,
    ads: false,
    bylineShow: false,
    timestampShow: false
  },
  "Turn on count": {
    countShow: true
  },
  "Turn off count": {
    countShow: false
  },
  "Hide on desktop": {
    hideOnDesktop: true
  },
  "Unhide on desktop": {
    hideOnDesktop: false
  }
};

/*
 * @param {object} customFields - The customFields
 *
 * @returns {string} - The canonical string of the mobilePreset.
 *
 * NOTE: Unlike for regular and mini presets, a legacyMap is not needed
 * for mini presets cuz it's not imporant to keep track if a name changes.
 *
 * This method was created b/c the preset macro expects each kind of preset
 * to behave the same in as many ways as possible
 */
export const getLastPickedMiniPreset = (customFields) =>
  customFields?.lastPickedMiniPreset;

export default Object.keys(presets).map((name) =>
  presetGenerator(name, presets[name])
);
