import PropTypes from "fusion-prop-types";
import {
  suppressDuplicatesOptions,
  discountDuplicatesOptions,
  dividerWeights,
  labelIcons,
  ctaLabelTypes
} from "~/components/features/fronts/utilities/common-front-properties";

// NOTE: When audio, bright, etc, are added, extend this list and extend
// other code as appropriate. Search for "video" in the carousel feature
// files to identify parts of code that need to be extended
export const carouselTypes = {
  audio: "Audio",
  "audio-playlist": "Audio Playlist (App-only)",
  bright: "Bright",
  "elex-live-graphic": "Election Compact Results Table",
  immersion: "Immersion",
  recipe: "Recipe",
  "vertical-video": "Vertical Video",
  video: "Video"
};

// NOTE: Add other app-feed types as necessary
export const appFeedTypes = Object.keys(carouselTypes).filter((type) =>
  /^(audio-playlist)$/.test(type)
);

export const defaults = {
  contentConfig: {
    contentService: "no-content",
    contentConfigValues: {}
  },
  rowSpan: 1,
  suppressDuplicates: "inherit",
  discountDuplicates: "inherit",
  horizontalDividerWeight: "normal",
  carouselType: "video",
  itemUrls: "",
  mobilePreset: "As is {but not hidden)",
  carouselCtaLabelType: "CTA"
};

export const CarouselPropTypes = {
  customFields: PropTypes.shape({
    carouselType: PropTypes.oneOf(Object.keys(carouselTypes)).tag({
      labels: carouselTypes,
      name: "Type of carousel",
      description: `Choose the type of carousel. Supported types: ${Object.keys(
        carouselTypes
      )
        .map((_) => carouselTypes[_])
        .join(", ")}`,
      defaultValue: defaults.carouselType
    }),
    carouselContentConfig: PropTypes.contentConfig(["carousel"]).tag({
      defaultValue: defaults.contentConfig,
      name: "Content Config"
    }),
    itemUrls: PropTypes.richtext.tag({
      name: "Item urls (max of 10)",
      description:
        "Enter content urls (or ids in the case of live graphics) separated by a return or space character(s). Max of 10 items will be shown.",
      defaultValue: defaults.itemUrls
    }),
    rowSpan: PropTypes.number.tag({
      name: "Row Spans",
      description:
        "If there is too much white space use this to adjust row height",
      defaultValue: defaults.rowSpan
    }),
    suppressDuplicates: PropTypes.oneOf(
      Object.keys(suppressDuplicatesOptions)
    ).tag({
      labels: suppressDuplicatesOptions,
      group: "DUPLICATES",
      name: "Suppress duplicates",
      description:
        "Select “Yes” to remove content from this feature that duplicates content displayed higher on the page. “No” keeps duplicates, while “Inherit” applies the chain-level setting. Default is “Inherit.”",
      defaultValue: defaults.suppressDuplicates,
      hidden: false
    }),
    discountDuplicates: PropTypes.oneOf(
      Object.keys(discountDuplicatesOptions)
    ).tag({
      labels: discountDuplicatesOptions,
      group: "DUPLICATES",
      name: "Ignore in duplication check",
      description:
        "Select “Yes” to exclude this feature’s content from duplicate detection, preventing it from affecting duplicate removal elsewhere. “No” includes it in checks, and “Inherit” uses the chain-level default. Default setting is “Inherit.”",
      defaultValue: defaults.discountDuplicates,
      hidden: false
    }),
    horizontalDividerWeight: PropTypes.oneOf(Object.keys(dividerWeights)).tag({
      labels: dividerWeights,
      name: "Horizontal divider",
      description:
        "Select the weight of the horizontal divider, which appears below the feature when there is another feature positioned below it",
      defaultValue: defaults.horizontalDividerWeight
    }),

    // START: LABEL
    carouselLabel: PropTypes.string.tag({
      group: "LABEL",
      name: "Text",
      description: "The label text. Optional.",
      defaultValue: ""
    }),
    carouselLabelUrl: PropTypes.string.tag({
      group: "LABEL",
      name: "Link",
      description: "The label link.",
      defaultValue: ""
    }),
    carouselLabelIcon: PropTypes.oneOf(Object.keys(labelIcons)).tag({
      group: "LABEL",
      name: "Select icon to prepend",
      description:
        "The label icon to prepend to the label. Defaults to 'None'.",
      defaultValue: "None"
    }),
    // END: LABEL

    // START: CTA
    carouselCtaLabelType: PropTypes.oneOf(Object.keys(ctaLabelTypes)).tag({
      labels: ctaLabelTypes,
      group: "Call To Action",
      name: "Type",
      defaultValue: defaults.ctaLabelType,
      hidden: false
    }),
    carouselCtaLabel: PropTypes.string.tag({
      group: "Call To Action",
      name: "Text",
      defaultValue: ""
    }),
    carouselCtaLabelUrl: PropTypes.string.tag({
      group: "Call To Action",
      name: "Link",
      defaultValue: ""
    }),
    // END: CTA

    mobilePreset: PropTypes.string.tag({
      name: "Mobile preset",
      description: "Select the mobile rendering of this feature.",
      defaultValue: defaults.mobilePreset,
      hidden: true
    })
  })
};
