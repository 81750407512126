import get from "lodash.get";
import { buildCanonicalUrl } from "~/components/utilities/wapo-canonical-url";
import {
  hasLocalStorage,
  hasSessionStorage
} from "~/components/utilities/hasLocalStorage";
import { removeWWW } from "~/components/utilities/www-remover";

/**
 * The content sources "foryou-flex-headlines" and "foryou-flex-headlines-by-surface"
 * share a lot functionality, esp. but not limited to readlist and exclusions. The
 * code they can share is here
 */

const baseEndpoint = {
  local: "https://foryou-flex-staging.washingtonpost.com/recommendations/v1",
  sandbox: "https://foryou-flex-staging.washingtonpost.com/recommendations/v1",
  prod: "https://foryou-flex.washingtonpost.com/recommendations/v1"
};

export const getEndpoints = (sourceName) => {
  switch (sourceName) {
    case "foryou-flex-headlines-by-surface":
      return {
        local: `${baseEndpoint.local}/categorical/`,
        sandbox: `${baseEndpoint.sandbox}/categorical/`,
        prod: `${baseEndpoint.prod}/categorical/`
      };
    case "foryou-flex-headlines":
    default:
      return {
        local: `${baseEndpoint.local}/articles/`,
        sandbox: `${baseEndpoint.sandbox}/articles/`,
        prod: `${baseEndpoint.prod}/articles/`
      };
  }
};

export const EMPTY = "[]";

/**
 * The list of chain display names to draw excluded content from
 */
export const chainDisplayNames = [
  "hp-banner-high",
  "hp-top-table-high",
  "hp-banner-main",
  "hp-top-table-main",
  "hp-more-top-stories"
];

/**
 * @param {array} Array of readlist objects where the u key is the url
 * @returns {array} array of urls (really just paths)
 */
const mapToUrl = ({ u }) => u;

/**
 * @returns {array} The sessionStorage.readlist4u value as an array.
 * sessionStorage.readlist4u IS maintained by this code
 */
const getReadlist4u = () =>
  JSON.parse(
    hasSessionStorage() ? sessionStorage.getItem("readlist4u") || EMPTY : EMPTY
  );

/**
 * @returns {array} The localStorage.readlist value as an array.
 * localStorage.readlist IS NOT maintained by this code
 */
const getReadlist = () =>
  JSON.parse(
    hasLocalStorage() ? localStorage.getItem("readlist") || EMPTY : EMPTY
  );

/**
 * @returns {array} A subset of the localStorage.readlist value as an array. Any item in
 * the array whose url ('u' value) is in sessionStorage.readlist4u is removed from the
 * returned array.
 */
export const getReadlistToUse = ({ excludeIfClicked = false }) => {
  const readlist = getReadlist();
  const readlist4u = excludeIfClicked ? [] : getReadlist4u();
  return readlist.filter(({ u }) => !readlist4u.map(mapToUrl).includes(u));
};

const getExclusionsFromList = (urls) => {
  return JSON.stringify(
    urls
      .map((url) => removeWWW(url))
      .filter(
        (_, i, self) => self.indexOf(_) === i // uniquify
      )
      .filter(Boolean)
      .filter((url) => /^\//.test(url))
  );
};

/**
 * @param {content} the 4u results
 * @returns {array} An array of urls fit for being added to the localStorage.exclusions value
 */
const getExclusionsFromContent = (content, existing) => {
  const fromContent = get(content, "items", []).map(
    ({ canonical_url }) => canonical_url
  );
  return getExclusionsFromList([...existing, ...fromContent]);
};

/**
 * @returns {array} The localStorage.exclusions value as an array.
 * "localStorage.exclusions IS maintained by this code
 */
const getExclusionsFromLocalStorage = () =>
  JSON.parse(
    hasLocalStorage() ? localStorage.getItem("exclusions") || EMPTY : EMPTY
  );

/**
 * @returns {array as string} The exclusions either from local storage
 * or from the page, depending on the strategy
 */
export const getExclusionsToUse = (exclusionStrategy, exclusionsFromPage) => {
  let exclusions = EMPTY;
  if (exclusionStrategy === "localStorage" && hasLocalStorage()) {
    exclusions = localStorage.getItem("exclusions") || EMPTY;
  } else if (exclusionStrategy === "page") {
    exclusions = getExclusionsFromList(exclusionsFromPage) || EMPTY;
  }
  return exclusions;
};

/**
 * Sets localStorage.exclusions based on existing value and new items in content
 * @param {content} the 4u results
 */
export const setExclusionsInLocalStorage = (content) => {
  if (hasLocalStorage()) {
    const existing = getExclusionsFromLocalStorage();
    const exclusions = getExclusionsFromContent(content, existing);
    if (exclusions.length) {
      localStorage.setItem("exclusions", exclusions);
    }
  }
};

/**
 * Removes localStorage.exclusions, which is removed at various points in the lifecyle of
 * the multi-view page
 */
export const removeAllExclusionsInLocalStorage = () => {
  if (hasLocalStorage()) {
    localStorage.removeItem("exclusions");
  }
};

/**
 * Adds a url to localStorage.readlist
 * @param {url} string - the url (pathname, really) to add
 */
export const addToReadlist = (item) => {
  const { u: url } = item;
  if (url && hasLocalStorage()) {
    const readlist = getReadlist();
    readlist.unshift(item);
    localStorage.setItem(
      "readlist",
      JSON.stringify(
        readlist
          .filter(
            ({ u }, i, self) => self.map(mapToUrl).indexOf(u) === i // uniquify
          )
          .slice(0, 50)
      )
    );
  }
};

/**
 * Adds a url to sessionStorage.readlist4u
 * @param {url} string - the url (pathname, really) to add
 */
export const addToReadlist4u = (item) => {
  const { u: url } = item;
  if (url && hasSessionStorage()) {
    const readlist4u = getReadlist4u();
    readlist4u.unshift(item);
    sessionStorage.setItem(
      "readlist4u",
      JSON.stringify(
        readlist4u
          .filter(
            ({ u }, i, self) => self.map(mapToUrl).indexOf(u) === i // uniquify
          )
          .slice(0, 50)
      )
    );
  }
};

export const getCanonicalUrl = (props) =>
  removeWWW(
    buildCanonicalUrl(props) ||
      (typeof window !== "undefined" ? window.location.pathname : undefined)
  );

export const getDomain = (env, endpoints) => {
  if (!env) env = "prod";
  return endpoints[env] || endpoints.prod;
};
