import { styled, theme } from "@washingtonpost/wpds-ui-kit";
import { CatchUpModulePropTypes } from "~/proptypes/catch-up-module";
import { getTimestampInfo } from "~/shared-components/story-card/_utilities/data";
import Sections from "./_children/Sections";
import sectionize from "./utilities/sectionize";
import useCatchUpContent from "./utilities/use-catch-up-content";

/**
 * Catch-Up Module
 *
 * Create a module that summarizes the most important or useful stories to serve as a destination for users to get
 *  caught up quickly, while expanding the footprint of what The Post has traditionally curated.
 * PRD: https://docs.google.com/document/d/1C6wn6MUmawQL3qtEP3PqvgVKAtz776Ojnem_T8a6M0E
 */
const CatchUpModule = ({
  catchUpUrl,
  tableLabelClasses,
  labelStyles,
  childStyles
}) => {
  const {
    content_elements: contentElements,
    last_updated_date: lastUpdatedDate
  } = useCatchUpContent(catchUpUrl);
  const sections = sectionize(contentElements);
  const ts = getTimestampInfo({
    time: lastUpdatedDate,
    thresholdInHours: Number.MAX_SAFE_INTEGER
  });

  const Header = styled("header", {
    alignSelf: "stretch",
    flexGrow: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: theme.space["025"],
    padding: `0 0 ${theme.space["075"]}`
  });
  const Timestamp = styled("span", {
    fontFamily: theme.fonts.meta,
    fontSize: theme.fontSizes["087"],
    fontWeight: theme.fontWeights.light,
    color: theme.colors.gray80
  });
  const Headline = styled("h2", {
    fontFamily: theme.fonts.headline,
    fontWeight: "bold",
    fontSize: theme.fontSizes[162],
    lineHeight: theme.lineHeights.headline,
    color: theme.colors.gray40
  });

  if (!contentElements?.length) return null;
  return (
    <div
      className={`hpgrid-item hpgrid-item--c-start hpgrid-item--c-spans hpgrid-close-the-gap dn-hp-md-to-xs ${tableLabelClasses}`}
      style={{
        ...(labelStyles || childStyles)
      }}
    >
      <Header>
        <Timestamp>{ts.ts ? `Updated ${ts.ts}` : ""}</Timestamp>
        <Headline>Get Caught Up</Headline>
      </Header>
      <Sections sections={sections} />
    </div>
  );
};

CatchUpModule.propTypes = CatchUpModulePropTypes;

export default CatchUpModule;
