import { useAppContext } from "fusion-context";
import useApi from "~/components/utilities/use-api";
import { getUserId, getJucid } from "~/components/utilities/login-details";
import { hasLocalStorage } from "~/components/utilities/hasLocalStorage";
import { transform } from "../helpers/transform/transform-foryou-flex-headlines";
import { useRenderedContentContext } from "../../components/contexts/rendered-content-context";
import {
  getEndpoints,
  EMPTY,
  getReadlistToUse,
  getExclusionsToUse,
  setExclusionsInLocalStorage,
  removeAllExclusionsInLocalStorage,
  addToReadlist,
  addToReadlist4u,
  getCanonicalUrl,
  getDomain,
  chainDisplayNames
} from "../helpers/foryou-flex";

/**
 * Makes a call to the foryou flex /articles endpoint
 *
 * docs:
 * https://foryou-flex-dev.perso.washpost.io/docs
 * https://foryou-flex-staging.washingtonpost.com/docs
 * https://foryou-flex.washingtonpost.com/docs ???
 *
 * */

const sourceName = "foryou-flex-headlines";

const defaultQuery = {
  env: "prod"
};

const endpoints = getEndpoints(sourceName);

// START: Needed to handle multi-view lifecycle
/**
 * Conditionally removes localStorage.exclusions on tab change
 * @param {activeTab} string - the active tab
 * @param {isAlwaysActive} bool - only remove if it's not always active
 */
const onTabChange = ({ activeTab, isAlwaysMounted }) => {
  if (sourceName === activeTab && !isAlwaysMounted)
    removeAllExclusionsInLocalStorage();
};

/**
 * Conditionally add url to sessionStorage.readlist4u
 * @param {a} object - the anchor element that should have a pathname
 * @param {activeTab} string - the active tab
 */
const onClick = ({ a, activeTab, ...rest }) => {
  if (sourceName === activeTab && a?.pathname) {
    const env = rest?.query?.query?.env;
    const t = new Date().getTime();
    const item = { u: a.pathname, t };
    addToReadlist4u(item);
    if (env !== "prod") {
      addToReadlist(item);
    }
  }
};

/**
 * Removes localStorage.exclusions when the page unloads
 */
const onUnload = () => {
  removeAllExclusionsInLocalStorage();
};
// END: Needed to handle multi-view lifecycle

const resolveHeaders = () => {
  return {
    accept: "application/json",
    "content-type": "application/json"
  };
};

const resolveBody = (query = {}) => {
  const {
    limit = 10,
    exclusionStrategy = "localStorage",
    exclusionsFromPage = [],
    surface,
    ctx,
    bp = "xs"
  } = query;

  let readlist = EMPTY;
  if (hasLocalStorage()) {
    readlist = JSON.stringify(getReadlistToUse(query));
  }

  const exclusions = getExclusionsToUse(exclusionStrategy, exclusionsFromPage);

  // NOTE: Don't send wapo_login_id if it's falsey
  const userId = getUserId();
  const wapoLoginId = userId ? `"wapo_login_id": "${userId}",\n` : "";

  return `{
    "limit": ${limit},
    "readlist": ${readlist},
    "exclusions": ${exclusions},
    "contentType": "story",
    "rules": {},
    "interface": "site",
    "surface": "${surface}",
    "surface_variant": "${bp}",
    ${wapoLoginId}
    "j_ucid": "${getJucid()}",
    "current_url": "${getCanonicalUrl(ctx)}"
  }`;
};

const resolveOptions = (query = {}) => ({
  method: "POST",
  credentials: "include",
  headers: resolveHeaders(query),
  body: resolveBody(query)
});

const resolve = (query = {}) => {
  query = { ...defaultQuery, ...query };
  const endpoint = getDomain(query.env, endpoints);
  return endpoint;
};

// NOTE: return truthy or falsey
const callback = (content, isAdmin) => {
  // NOTE: Don't want results getting exhausted in the admin
  if (isAdmin) return true;
  if (content?.items) {
    setExclusionsInLocalStorage(content);
    return true;
  }
  return false;
};

/**
 *
 * foryou /articles endpoint contract can be found at
 * https://paper.dropbox.com/doc/ForYouUser-Info-Data-Contract--B~G5Qr8TaxysBg~3FLulbGugAg-gC9BKEVuvD5R6z2Vd9zZP
 *
 */
const useHook = (query = {}, isReady) => {
  const ctx = useAppContext();
  const { getRenderedContent } = useRenderedContentContext();
  const exclusionsFromPage = getRenderedContent({ chainDisplayNames }).map(
    ([, v]) => v.canonical_url
  );
  const withEnhancements = { exclusionsFromPage, ctx, ...query };
  const options = resolveOptions(withEnhancements);
  const endpoint = resolve(withEnhancements);
  const config = { callback, transform: (data) => transform(data, { query }) };
  const data = useApi({ endpoint, options, isReady, ...config });
  return data;
};

export default {
  resolveOptions,
  resolve,
  params: {
    // The below parameters are determined programmatically
    bp: "text",
    env: "text",
    // The below parameters are provided through OverrideFormWidget in the admin
    limit: "number",
    surface: "text"
  },
  callback,
  transform,
  handlers: { onTabChange, onClick, onUnload },
  useHook,
  defaultContentConfig: {
    exclusionStrategy: "page"
  }
};
