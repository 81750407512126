import get from "lodash.get";

import createCaptionAndCredits from "../../anglerfish/caption-and-credits/index";

/**
 * Receives an ANS content object and creates a formatted image_to_use object for
 * use by features and components. In ANS, the various bits to harvest are structured thus:

  {
    _id: anglerfish id
    type: string:image,
    url: string: url of the image,
    alt_text: string, // undefined if target !== bright
    credits_display: string,
    credits_caption_display: string, // credits_display if target === bright
    width: int,
    height: int,
    image_type: string,
    aspect_ratio: width / height,
    provenance: string: where the image came from
  }
 */

export const parseId = (url) => {
  if (!url || typeof url !== "string" || url.indexOf("arc-anglerfish") === -1)
    return undefined;
  const re = /.*\/([A-Z0-9]+)(?:_size-normalized)?\.\w+$/;
  // NOTE: try/catch cuz url could be a malformed uri incapable of being decoded
  try {
    return re.exec(decodeURIComponent(url))?.[1];
  } catch (e) {
    return re.exec(url)?.[1];
  }
};

/*
 * @param {object} promoImage - object with image data
 * @return {string} - image url
 *
 * NOTE: Will return the safe image if available AND its id matches
 * the id of the normal image. If they don't match, this means the
 * image was overidden by editors in a wsk collection and the normal
 * image url is returned
 */
export const getPromoImageUrl = (promoImage) => {
  const url = get(promoImage, "url");
  const id = parseId(url);
  // NOTE: If no id, there will be no safe url, so return url
  if (!id) return url;
  const sizeNormalizedUrl = get(
    promoImage,
    "additional_properties.size_normalized_url"
  );
  // NOTE: If no safe url, return url;
  if (!sizeNormalizedUrl) return url;
  const sizeNormalizedId = parseId(sizeNormalizedUrl);
  // eslint-disable-next-line no-negated-condition
  return id !== sizeNormalizedId ? url : sizeNormalizedUrl;
};

export default function createPromoImage(ans, target = "promo") {
  let promoImage;
  let provenance;
  let type;
  let url;

  const checkList = /^bright$/.test(target)
    ? ["promo_items.bright"]
    : [
        "additional_properties.lead_art",
        "promo_items.basic",
        "related_content.primary_slot[0]"
      ];

  // NOTE: Check for images in priority order
  checkList.forEach((key) => {
    if (!getPromoImageUrl(promoImage)) {
      const image = get(ans, key, {});
      ({ type, url } = image);
      if (type === "image" && url) {
        promoImage = image;
        provenance = key;
      }
    }
  });

  if (!promoImage) return undefined;

  const { _id, width, height, image_type: imageType } = promoImage;
  url = getPromoImageUrl(promoImage);

  let {
    alt_text: altText,
    credits_display: creditsDisplay,
    caption_display: captionDisplay,
    credits_caption_display: creditsCaptionDisplay
  } = promoImage;

  if (!creditsDisplay) {
    // WARNING: createCaptionAndCredits() returns a dangerous raw caption.
    // Under no conditions should that be retrieved or made part of
    // an object that could end up in the DOM of an HTML file we serve
    ({
      alt_text: altText,
      credits_display: creditsDisplay,
      caption_display: captionDisplay,
      credits_caption_display: creditsCaptionDisplay
    } = createCaptionAndCredits(promoImage, target) || {});
  }
  if (!creditsCaptionDisplay) creditsCaptionDisplay = creditsDisplay;

  // NOTE: Brights fall back to captionDisplay, regular images do not
  const altTextToUse = /^bright$/.test(target)
    ? altText || captionDisplay
    : altText;

  return {
    _id: _id || parseId(url), // NOTE: If no _id, try to parse it from the URL.
    type,
    url: decodeURIComponent(url),
    alt_text: altTextToUse || undefined,
    credits_display: creditsDisplay,
    credits_caption_display: /^bright$/.test(target)
      ? creditsDisplay
      : creditsCaptionDisplay,
    width,
    height,
    image_type: imageType,
    aspect_ratio: width && height ? width / height : undefined,
    provenance
  };
}
