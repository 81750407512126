import { sendGAEvent } from "@washingtonpost/site-analytics";
import { ThumbsDown, ThumbsUp } from "@washingtonpost/wpds-assets";
import { Icon, styled, theme } from "@washingtonpost/wpds-ui-kit";
import { Fragment, useState } from "react";

const UP_FORM_URL =
  "https://thewashingtonpost.formstack.com/forms/home_caught_up_thumbs_up";
const DOWN_FORM_URL =
  "https://thewashingtonpost.formstack.com/forms/home_caught_up_thumbs_down";

const Feedback = () => {
  const [helpful, setHelpful] = useState();

  const onClickYes = () => {
    setHelpful(true);
    sendGAEvent("onpage-click", "feedback_form", "thumbs_up", {
      eventDimension: "catchup_module"
    });
  };
  const onClickNo = () => {
    setHelpful(false);
    sendGAEvent("onpage-click", "feedback_form", "thumbs_down", {
      eventDimension: "catchup_module"
    });
  };
  const Wrapper = styled("div", {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
    color: theme.colors.gray80,
    flexWrap: "wrap",
    rowGap: theme.space["025"]
  });
  const Complete = styled("span", {
    flexGrow: 1,
    fontFamily: theme.fonts.meta,
    fontWeight: theme.fontWeights.light,
    fontSize: theme.fontSizes["087"],
    lineHeight: theme.lineHeights.meta,
    color: theme.colors.gray20,
    a: {
      textDecoration: "underline",
      color: theme.colors.gray20
    }
  });
  const Helpful = styled("span", {
    fontFamily: theme.fonts.meta,
    fontWeight: theme.fontWeights.light,
    fontSize: theme.fontSizes["087"],
    lineHeight: theme.lineHeights.meta,
    textWrap: "nowrap"
  });
  const Thumbs = styled("div", {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.sizes[100],
    padding: 0
  });
  const YesNo = styled("div", {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.sizes["025"],
    padding: 0,
    cursor: "pointer",
    "&:hover": {
      color: theme.colors.gray20,
      "& > svg": {
        fill: theme.colors.gray20
      }
    }
  });

  return (
    <Wrapper>
      {typeof helpful !== "undefined" ? (
        <Complete>
          Thanks for your feedback!{" "}
          <a href={helpful ? UP_FORM_URL : DOWN_FORM_URL}>Tell us more.</a>
        </Complete>
      ) : (
        <Fragment>
          <Helpful>Was this briefing helpful?</Helpful>
          <Thumbs>
            <YesNo onClick={onClickYes}>
              <Icon label="yes" size="100" fill={theme.colors.gray80}>
                <ThumbsUp />
              </Icon>
              Yes
            </YesNo>
            <YesNo onClick={onClickNo}>
              <Icon label="no" size="100" fill={theme.colors.gray80}>
                <ThumbsDown />
              </Icon>
              No
            </YesNo>
          </Thumbs>
        </Fragment>
      )}
    </Wrapper>
  );
};

export default Feedback;
