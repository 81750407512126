import { useCallback, useEffect, useState } from "react";

class NoEndpointError extends Error {
  constructor(status, source, message) {
    super();
    this.status = status;
    this.message = message || "No endpoint provided to useApi";
    this.source = source;
  }
}

export const apiStates = {
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
};

async function fetchJSON({ endpoint, options }) {
  if (!endpoint) throw new NoEndpointError();
  return fetch(endpoint, options);
}

/**
 * @param {endpoint} string - url
 * @param {options} object - options for fetch
 * @param {config} object - config containing callback, transform, etc.
 */
export const useApi = ({ endpoint, options = {}, ...config } = {}) => {
  const { callback, transform, isReady } = config;

  const [data, setData] = useState({
    state: apiStates.LOADING,
    error: undefined,
    content: undefined
  });
  const setPartialData = (partialData) => setData({ ...data, ...partialData });
  const [hasCalledBack, setCalledBack] = useState(false);

  const fetchData = useCallback(() => {
    fetchJSON({ endpoint, options })
      .then((response) => response.json())
      .then((content) => {
        if (transform) content = transform(content);
        if (callback && !hasCalledBack) setCalledBack(callback(content));
        setPartialData({
          state: apiStates.SUCCESS,
          content
        });
      })
      .catch((e) => {
        setPartialData({
          state: apiStates.ERROR,
          error: e,
          content: {}
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, options]);

  useEffect(() => {
    if (isReady) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  return data;
};

export default useApi;
