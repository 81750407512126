import { useContent } from "fusion-content";

const tickerContentConfig = {
  source: "aggregator-news-ticker",
  query: {
    env: "prod",
    exclusions: []
  }
};

const emptyContentConfig = { source: "empty" };

export const usePrismNewsTickerData = (isOk) => {
  return useContent(isOk ? tickerContentConfig : emptyContentConfig);
};
