import { styled, theme } from "@washingtonpost/wpds-ui-kit";
import PropTypes from "prop-types";
import { ContentPropTypes } from "~/proptypes/catch-up-module";

const Header = ({ element, icon }) => {
  const H = styled("h3", {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "6px",
    padding: 0,
    span: {
      fontFamily: theme.fonts.meta,
      fontSize: theme.fontSizes["100"],
      fontWeight: theme.fontWeights.bold,
      alignSelf: "stretch",
      lineHeight: theme.lineHeights.meta,
      color: theme.colors.gray0
    }
  });

  const { content, _id } = element;
  return (
    <H id={_id}>
      {icon}
      <span dangerouslySetInnerHTML={{ __html: content }} />
    </H>
  );
};
Header.propTypes = { ...ContentPropTypes, icon: PropTypes.object };

export default Header;
