import React from "react";
import { useAppContext } from "fusion-context";

import { getClasses } from "@washingtonpost/front-end-utils";
import { styled, theme, Icon } from "@washingtonpost/wpds-ui-kit";
import Close from "@washingtonpost/wpds-assets/asset/close";

import useLiveEventContent from "~/shared-components/useLiveEvent";
import { useMultiView } from "~/shared-components/MultiViewContext";
import { LiveAlertPropTypes } from "../../../../proptypes/live-alert";

const LiveUpdateHpAlert = () => {
  const [breakingNewsContent, ackContent] =
    useLiveEventContent("breaking-news-bar");

  if (!breakingNewsContent || !breakingNewsContent.content) {
    return null;
  }

  return <HpAlert customFields={breakingNewsContent} dismiss={ackContent} />;
};

const StyledAlertContainer = styled("div", {
  // mobile top is set in css prop since we need to get values from context
  backgroundColor: theme.colors["red100-static"],
  position: "sticky; position: -webkit-sticky",
  width: "100%",
  zIndex: 5, // one less than the header
  "@notSm": {
    top: "60px"
  },
  variants: {
    isAdmin: {
      true: {
        position: "unset"
      }
    }
  }
});

const StyledAnchor = styled("a", {
  "> .alert-text": {
    color: theme.colors["gray700-static"]
  }
});

const getValueWithNoUnit = (valueWithUnit) =>
  Number(/^(\d+)(px)?$/.exec(valueWithUnit)?.[1] || 0);

export const HpAlert = ({ customFields, dismiss }) => {
  const { url, content, prefix } = customFields;
  const { isMultiView, height, top } = useMultiView();
  const { isAdmin } = useAppContext();

  const multiviewTopPosition = getValueWithNoUnit(top);
  const multiviewHeight = isMultiView ? getValueWithNoUnit(height) : 0;

  // eslint-disable-next-line react/prop-types
  const TextWrapper = ({ children }) => {
    return (
      <span className="alert-text" data-qa="alert-text">
        {children}
      </span>
    );
  };

  const ContentWrapper = url
    ? ({ children }) => (
        <StyledAnchor className="alert-url" href={url}>
          <TextWrapper>{children}</TextWrapper>
        </StyledAnchor>
      )
    : TextWrapper;

  return (
    <React.Fragment>
      {content && (
        <StyledAlertContainer
          className={getClasses("hide-for-print flex")}
          data-link-group="alert"
          css={{ top: multiviewTopPosition + multiviewHeight }}
          isAdmin={isAdmin}
        >
          <div className="flex ml-auto mt-sm mb-sm mr-auto banner-max-width banner-ml w-100">
            <div className="flex w-100 banner-content-wrapper">
              <ContentWrapper>
                <strong className="bold font-xs">{prefix}</strong>
                {content}
              </ContentWrapper>
            </div>
          </div>
          <button onClick={dismiss} className="ma-sm pointer self-baseline">
            <Icon
              label="close"
              className="fill-current"
              style={{
                opacity: "80%",
                fill: theme.colors["gray700-static"]
              }}
            >
              <Close />
            </Icon>
          </button>
        </StyledAlertContainer>
      )}
    </React.Fragment>
  );
};

HpAlert.propTypes = LiveAlertPropTypes;
export default LiveUpdateHpAlert;
