import PropTypes from "prop-types";

export const CatchUpModulePropTypes = {
  catchUpUrl: PropTypes.string,
  tableLabelClasses: PropTypes.string,
  labelStyles: PropTypes.object,
  childStyles: PropTypes.object
};

export const ContentPropTypes = {
  element: PropTypes.shape({
    items: PropTypes.array,
    content: PropTypes.any,
    type: PropTypes.string
  })
};

export const SectionPropTypes = {
  section: PropTypes.shape({})
};
