import get from "lodash.get";
import { getEntitlements } from "@washingtonpost/subs-sdk";
import {
  getPublishedDate,
  getCredits,
  getSeoKeywords,
  getKeywords,
  getCommercialNode,
  getAuxilaries
} from "../ans-helper";
import getCookie from "../cookies";
import { hasLocalStorage } from "../hasLocalStorage";
import { getQueryParams } from "../history";

const PREV_PAGE_KEY = "wapo_prev_page";

/**
 * Convert a list to a semicolon delimited string, optionally based on a property within each object in the list.
 *
 * Example:
 *  const list = { a: 1, a: 2 }, { a: 3, b: 4 }, { a: 5, b: 6 };
 *  const result = convertToDelimitedString(list, "a");
 *  console.log(result) => "1;3;5"
 *
 * @param {array} list A collection that will be converted to a delimited string
 * @param {string} prop (optional) The property within each item in the list
 * @returns {string} The delimited string
 */
export function convertToDelimitedString(list, prop) {
  return list
    .filter((item) => {
      // Filter out any empty items
      if (prop) {
        return get(item, prop) ? item : null;
      }
      return item || null;
    })
    .map((item) => (prop ? get(item, prop, "").trim() : item.trim()))
    .join(";")
    .toLowerCase();
}

/**
 * Get the previous page name from local storage
 *
 * @param {string} currentPageName The current page name from ANS
 * @returns {string} The previous page name
 */
function getPreviousPage(currentPageName) {
  if (!hasLocalStorage()) {
    return null;
  }
  const prevPage = window.localStorage.getItem(PREV_PAGE_KEY);
  if (prevPage === currentPageName) return null;

  return prevPage;
}

/**
 * Sets the current page name in local storage
 * @param {string} currentPageName The current page name from ANS
 */
function setPreviousPage(currentPageName) {
  if (!hasLocalStorage()) return;
  window.localStorage.setItem(PREV_PAGE_KEY, currentPageName);
}

/**
 * Fire a page view event
 * @param {object} props The global ANS props
 * @param {object} pwapi The pwapi object
 * @param {object} props extra props needed for tracking
 */
export function pageView(globalContent, pwapi, props) {
  const defaultValue = "(not set)";
  const credits = getCredits(globalContent);
  const tracking = get(
    globalContent,
    "taxonomy.tracking",
    globalContent.tracking || {}
  );
  const topicList = get(globalContent, "taxonomy.topics", []);
  const sourceId = get(globalContent, "source.source_id");
  const keywordList = getKeywords(globalContent);
  const keywords = {
    list: getKeywords(globalContent),
    delimited: convertToDelimitedString(keywordList)
  };
  const currentPageName = tracking.in_url_headline;

  // Set subsection
  let { subsection = defaultValue } = tracking;
  subsection = subsection.trim();

  // Set variables that are delimited from a list (e.g. example converted value: "a1;a2;a3")
  const authorNames = convertToDelimitedString(credits, "name");
  const authorTypes = convertToDelimitedString(
    credits,
    "additional_properties.original.author_type"
  );
  const authorIds = convertToDelimitedString(credits, "_id");
  const authorDesk = convertToDelimitedString(
    credits,
    "additional_properties.original.custom_washpost_desk_name_1"
  );
  const authorSubdesk = convertToDelimitedString(
    credits,
    "additional_properties.original.custom_washpost_desk_name_2"
  );
  const seoKeywords = convertToDelimitedString(getSeoKeywords(globalContent));
  const topics = convertToDelimitedString(topicList, "uid");

  // Set content tags
  const allTags = get(globalContent, "taxonomy.tags", []);
  const contentTags = convertToDelimitedString(allTags, "text");

  // Set an object that contains all necessary cookies to track
  const cookies = {
    rplampr: getCookie("rplampr"),
    wpVariant: getCookie("wp_variant"),
    wapoProvider: (
      decodeURIComponent(getCookie("wapo_provider")) || defaultValue
    ).toLowerCase(),
    wapoLoginId: getCookie("wapo_login_id"),
    wapoActmgmt: getCookie("wapo_actmgmt"),
    wpCrtid: getCookie("wp_crtid")
  };

  // Determine the previous page name
  const prevPage = getPreviousPage(currentPageName);

  const cookiesHit =
    cookies.rplampr && cookies.wpVariant
      ? `${cookies.rplampr};${cookies.wpVariant}`
      : defaultValue;

  const canonicalUrl = globalContent.canonical_url || defaultValue;

  const auxiliaries = convertToDelimitedString(
    getAuxilaries(globalContent),
    "uid"
  );

  const isFBIA = typeof ia_document === "undefined" ? false : "fbia"; // eslint-disable-line camelcase

  const meterReason = get(pwapi, "data.meterState", defaultValue);
  const meterCount = get(pwapi, "data.meterCount", defaultValue);
  const meterWeight = get(pwapi, "data.articleWeight", defaultValue);
  const ip = get(pwapi, "data.ipAddress", defaultValue);

  // check the status of the adblocker and private mode
  let adBlockFlag = "notdetected";
  let privateModeFrontEnd = "standard";
  // if adblocker or private mode is not detected, then TWP.Data.Tracking.blockers will not be a valid object
  const adblockTracking = get(window, "TWP.Data.Tracking.blockers");
  if (adblockTracking) {
    // if adblocker is blocking content, then TWP.Data.Tracking.blockers.ad = true
    // if adblocker is not blocking content, then TWP.Data.Tracking.blockers.ad = false
    adBlockFlag = get(adblockTracking, "ad") ? "blocked" : "notblocked";
    // if in private mode, then TWP.Data.Tracking.blockers.pv = true
    // if not in private mode, then TWP.Data.Tracking.blockers.pv = false
    privateModeFrontEnd = get(adblockTracking, "pv") ? "private" : "standard";
  }

  /**
   * Our section fronts' metadata is using site service
   * and we need to resolve the values being returned.
   *
   * This function should take and transform, for example,
   * a query from a page with elex (any site service) backing content:
   * {{content.q_results[0].site.tracking_section}} >> elections.
   */
  const fusionMetas = get(window, "Fusion.metas");

  if (fusionMetas) {
    Object.keys(fusionMetas).forEach((key) => {
      let value = get(fusionMetas, `${key}.value`);
      // NOTE: matches, e.g., "before - {{content.whatever_1}} - inbetween - {{content.whatever_2}} - etc"
      // match[1] = "{{content.whatever_1|2}}"
      // match[2] = "whatever_1|2" and will be resolved to a value in globalContent
      let match = /^.*({{content\.(.*)}}).*$/g.exec(value);
      while (match) {
        value = value.replace(match[1], get(globalContent, match[2], ""));
        fusionMetas[key].value = value;
        match = /^.*({{content\.(.*)}}).*$/g.exec(value);
      }
    });
  }

  const fusionMetasCanonicalUrl = get(fusionMetas, "canonicalUrl.value");
  const fusionMetasCommercialNode = get(fusionMetas, "commercial_node.value");
  const fusionMetasValue = get(fusionMetas, "contentType.value");
  const fusionMetasPageName = get(fusionMetas, "pageName.value");
  const fusionMetasSection = get(fusionMetas, "section.value");
  const fusionMetasSubsection = get(fusionMetas, "subsection.value");

  window.dataLayer.push({
    adBlockFlag, // response from TWP.Data.Tracking object
    appSection: props?.appSection || defaultValue,
    arcId: globalContent._id || defaultValue,
    authorId: authorIds || defaultValue,
    authorName: authorNames || defaultValue,
    authorType: authorTypes || defaultValue,
    auxiliaries,
    canonicalUrl: fusionMetasCanonicalUrl || canonicalUrl,
    clavisKeywords: keywords.delimited || defaultValue,
    clavisTopics: topics || defaultValue,
    cmsId: sourceId || defaultValue,
    cmsName: get(globalContent, "source.system", defaultValue),
    commercialNode:
      fusionMetasCommercialNode || getCommercialNode(globalContent),
    contentSource: get(globalContent, "owner.name", defaultValue),
    contentTags,
    contentTopics: get(tracking, "content_topics", defaultValue),
    contentType:
      fusionMetasValue ||
      `${get(globalContent, "type", defaultValue)}${
        globalContent.subtype ? ` - ${globalContent.subtype}` : ""
      }`,
    cookiesHit,
    event: "fusionPageView",
    inlineElements: defaultValue, // TODO: we are not sure yet where the value for this comes from
    ip,
    iptcTopicsArray: topics || defaultValue,
    itid: getQueryParams(window.location.href).itid || defaultValue,
    language: globalContent.language || defaultValue,
    loginTypeHit: cookies.wapoProvider || defaultValue,
    meterReason,
    meterCount,
    meterWeight,
    meterType: get(
      globalContent,
      "content_restrictions.content_code",
      defaultValue
    ), // TODO: does it exist?
    newsroomDesk: authorDesk || defaultValue,
    newsroomSubdesk: authorSubdesk || defaultValue,
    pageName: fusionMetasPageName || currentPageName || defaultValue,
    pageViewType: "load", // Hardcode to "load" for article pages / Newsreader is going to be phased out in the next 4-ish weeks. / My Post reading list, when implemented, will be considered a "virtual"
    platformType: isFBIA || "assembler",
    previousPage: prevPage || defaultValue,
    privateModeFrontEnd, // response from TWP.Data.Tracking object
    propensityScoreHit: defaultValue, // TODO: we are not sure yet where the value for this comes from
    property: "website",
    publishDate: getPublishedDate(globalContent) || defaultValue,
    renderingEngine: "assembler",
    seoKeywords: seoKeywords || defaultValue,
    section: fusionMetasSection || tracking.section || defaultValue,
    subscriberAttributesHit: cookies.wapoActmgmt || defaultValue,
    subscriptionAttributesHit: getEntitlements() || defaultValue,
    subsection: fusionMetasSubsection || subsection,
    testGroupHit: defaultValue, // TODO: leaving blank for now
    tid: getQueryParams(window.location.href).tid || defaultValue,
    uuidEmailHit: cookies.wpCrtid || defaultValue,
    uuidHit: cookies.wapoLoginId || defaultValue,
    userAgentHit: navigator.userAgent || defaultValue
  });

  // Setting the previous page name in local storage so we can access it on the next page view
  setPreviousPage(currentPageName);
}
