import { ArrowRight } from "@washingtonpost/wpds-assets";
import { Icon, InputText, styled, theme } from "@washingtonpost/wpds-ui-kit";
import { SectionPropTypes } from "~/proptypes/catch-up-module";
import Header from "./Header";

/**
 * SVG that is a solid version of the Ai icon from WPDS.
 *
 * This is destined to be added to WPDS eventually.
 */
const AiSolid = (
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.47501 1.84378C8.40753 1.63864 8.21599 1.5 8.00004 1.5C7.78409 1.5 7.59255 1.63864 7.52507 1.84378L6.25041 5.71912C6.20963 5.84308 6.14032 5.95573 6.04805 6.04801C5.95577 6.14028 5.84311 6.2096 5.71915 6.25037L1.84382 7.52504C1.63868 7.59251 1.50004 7.78405 1.50004 8C1.50004 8.21595 1.63868 8.4075 1.84382 8.47497L5.71915 9.74964C5.84312 9.79041 5.95577 9.85972 6.04805 9.952C6.14032 10.0443 6.20964 10.1569 6.25041 10.2809L7.52507 14.1562C7.59255 14.3614 7.78409 14.5 8.00004 14.5C8.21599 14.5 8.40753 14.3614 8.47501 14.1562L9.74968 10.2809C9.79045 10.1569 9.85976 10.0443 9.95204 9.952C10.0443 9.85972 10.157 9.79041 10.2809 9.74964L14.1563 8.47497C14.3614 8.4075 14.5 8.21595 14.5 8C14.5 7.78405 14.3614 7.59251 14.1563 7.52504L10.2809 6.25037C10.157 6.2096 10.0443 6.14028 9.95204 6.04801C9.85976 5.95573 9.79045 5.84307 9.74967 5.71911L8.47501 1.84378ZM6.93782 6.22945L8.00004 3L9.06226 6.22945C9.11662 6.39473 9.20904 6.54494 9.33208 6.66797C9.45511 6.791 9.60531 6.88342 9.7706 6.93778L13 8L9.7706 9.06222C9.60531 9.11659 9.45511 9.20901 9.33208 9.33204C9.20904 9.45507 9.11662 9.60528 9.06226 9.77056L8.00004 13L6.93782 9.77056C6.88346 9.60528 6.79104 9.45507 6.66801 9.33204C6.54497 9.20901 6.39477 9.11659 6.22949 9.06222L3.00004 8L6.22949 6.93778C6.39477 6.88342 6.54497 6.791 6.66801 6.66797C6.79104 6.54494 6.88346 6.39473 6.93782 6.22945Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33375 1.45834C3.6329 1.45834 3.87541 1.70085 3.87541 2V4.66667C3.87541 4.96582 3.6329 5.20834 3.33375 5.20834C3.03459 5.20834 2.79208 4.96582 2.79208 4.66667V2C2.79208 1.70085 3.03459 1.45834 3.33375 1.45834Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6663 10.7932C12.9655 10.7932 13.208 11.0357 13.208 11.3348V14.0015C13.208 14.3006 12.9655 14.5432 12.6663 14.5432C12.3672 14.5432 12.1247 14.3006 12.1247 14.0015V11.3348C12.1247 11.0357 12.3672 10.7932 12.6663 10.7932Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.45837 3.33482C1.45837 3.03567 1.70089 2.79316 2.00004 2.79316H4.66671C4.96586 2.79316 5.20837 3.03567 5.20837 3.33482C5.20837 3.63398 4.96586 3.87649 4.66671 3.87649H2.00004C1.70089 3.87649 1.45837 3.63398 1.45837 3.33482Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7921 12.6652C10.7921 12.366 11.0346 12.1235 11.3337 12.1235H14.0004C14.2996 12.1235 14.5421 12.366 14.5421 12.6652C14.5421 12.9643 14.2996 13.2068 14.0004 13.2068H11.3337C11.0346 13.2068 10.7921 12.9643 10.7921 12.6652Z"
    />
    <path d="M8.00004 3L6.93782 6.22945C6.88346 6.39473 6.79104 6.54494 6.66801 6.66797C6.54497 6.791 6.39477 6.88342 6.22949 6.93778L3.00004 8L6.22949 9.06222C6.39477 9.11659 6.54497 9.20901 6.66801 9.33204C6.79104 9.45507 6.88346 9.60528 6.93782 9.77056L8.00004 13L9.06226 9.77056C9.11662 9.60528 9.20904 9.45507 9.33208 9.33204C9.45511 9.20901 9.60531 9.11659 9.7706 9.06222L13 8L9.7706 6.93778C9.60531 6.88342 9.45511 6.791 9.33208 6.66797C9.20904 6.54494 9.11662 6.39473 9.06226 6.22945L8.00004 3Z" />
  </svg>
);

/**
 * The Ask the Post AI section in the Catch-Up Module.
 *
 * Provides some styling and the all-important form.
 */
const AskThePostAi = ({ section }) => {
  const { items = [] } = section;
  const header = items[0];
  const defaultValue = items[1]?.content;

  const Wrapper = styled("section", {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "stretch",
    gap: theme.space["050"],
    padding: 0
  });

  if (!header) return null;

  return (
    <Wrapper>
      <Header
        element={header}
        icon={
          <Icon
            label="ai"
            css={{
              border: `solid 0.4px ${theme.colors.gray0}`,
              backgroundColor: theme.colors.gray700,
              height: "14px",
              width: "14px",
              padding: "1.3px"
            }}
          >
            {AiSolid}
          </Icon>
        }
      ></Header>
      <form
        role="search"
        method={"GET"}
        action={"https://www.washingtonpost.com/search/"}
      >
        <InputText
          defaultValue={defaultValue}
          type="text"
          label="Ask a question or search"
          buttonIconType="submit"
          buttonIconText="submit"
          name="query"
          icon="right"
          id="query"
          css={{
            padding: 0,
            div: {
              paddingLeft: theme.space["075"],
              paddingTop: theme.space["150"],
              paddingBottom: theme.space["075"],
              paddingRight: theme.space["050"]
            },
            button: {
              borderRadius: theme.radii.round,
              color: theme.colors.onPrimary,
              backgroundColor: theme.colors.primary,
              padding: theme.space["050"]
            },
            "button:hover": {
              backgroundColor: theme.colors.gray60
            },
            input: {
              fontSize: theme.fontSizes["100"],
              fontWeight: theme.fontWeights.light
            },
            "span[role=label]": {
              insetInlineStart: theme.space["075"],
              top: theme.space["025"]
            }
          }}
        >
          <Icon size="100">
            <ArrowRight />
          </Icon>
        </InputText>
      </form>
    </Wrapper>
  );
};
AskThePostAi.propTypes = SectionPropTypes;

export default AskThePostAi;
