import { useEffect, useState, useCallback, useRef, Fragment } from "react";
import { useAppContext } from "fusion-context";
import get from "lodash.get";
import { useMultiView } from "~/shared-components/MultiViewContext";
import {
  sendGAPageview,
  sendOmniturePageview
} from "../../utilities/analytics/analytics";
import ErrorBoundary from "~/shared-components/ErrorBoundary";
import permutiveTrigger from "../../output-types/shared/permutive";

/**
 * Dispatches a pageview for anything using the analytics bundle
 */
const Analytics = () => {
  const { globalContent, isAdmin, metaValue } = useAppContext();
  const { isReady, isMultiView, initialTab: appSection } = useMultiView();
  const fusionAdditions = get(globalContent, "fusion_additions", {});
  const { tracking } = fusionAdditions;

  const isFront = metaValue("contentType") === "front";

  const [trackingSent, setTrackingSent] = useState(false);
  const hasRegisteredPwapiConsumer = useRef(null);

  const sendTracking = useCallback(
    (pwapi) => {
      /**
       * Only pushing when not in the admin.
       */
      if (!isAdmin) {
        const payload = { ...tracking };
        if (
          (pwapi.status === "final" || pwapi.status === "error") &&
          !trackingSent &&
          !hasRegisteredPwapiConsumer.current
        ) {
          const props = isMultiView ? { appSection } : {};
          hasRegisteredPwapiConsumer.current = true;

          setTrackingSent(true);

          // 1. Send the omniture page view and include the analytics bundle
          //    but only on the section fronts, not on the homepage
          if (isFront) sendOmniturePageview(payload, { reset: true });

          // 2. Send Google Analytics page view
          sendGAPageview(globalContent, pwapi, props);

          // 3. Check consent and send a page view to Permutive
          permutiveTrigger();
        }
      }
    },
    [
      globalContent,
      isMultiView,
      appSection,
      isAdmin,
      isFront,
      tracking,
      trackingSent
    ]
  );

  useEffect(() => {
    if (isReady && window.registerPwapiConsumer && !trackingSent) {
      window.registerPwapiConsumer(sendTracking);
    }

    const timeout = setTimeout(() => {
      if (!hasRegisteredPwapiConsumer.current) {
        sendTracking({ status: "error" });
      }
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [sendTracking, trackingSent, isReady]);

  return (
    <ErrorBoundary>
      <Fragment />
    </ErrorBoundary>
  );
};

export default Analytics;
