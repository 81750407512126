import { Fragment, useEffect, useState } from "react";
import get from "lodash.get";
import { getClasses } from "@washingtonpost/front-end-utils";
import { styled, theme, Icon } from "@washingtonpost/wpds-ui-kit";
import Close from "@washingtonpost/wpds-assets/asset/close";
import ChevronUp from "@washingtonpost/wpds-assets/asset/chevron-up";
import ChevronDown from "@washingtonpost/wpds-assets/asset/chevron-down";
import { useFusionContext } from "fusion-context";
import Warning from "~/shared-components/Warning";
import { HandleFetchErrors } from "~/components/features/fronts/flex-feature/utilities/handle-fetch-errors";
import Headline from "./_children/Headline.jsx";
import PlaylistItems from "./_children/PlaylistItems.jsx";
import Video from "~/shared-components/video/default.jsx";
import useLiveEventContent from "~/shared-components/useLiveEvent.jsx";
import { useDismissIfEnded, useLiveVideoData } from "./livebar-hooks";
import { LiveVideoPropTypes } from "~/proptypes/live-video-bar";
import { useAssemblerContext } from "~/shared-components/AssemblerContext";

const StyledBar = styled("div", {
  backgroundColor: theme.colors["gray40-static"],
  zIndex: 1,
  position: "sticky"
});

const StyledToggleBar = styled("div", {
  height: "24px",
  color: theme.colors["gray200-static"],
  backgroundColor: theme.colors["gray60-static"],
  borderTop: `1px solid ${theme.colors["gray80-static"]}`
});

const LiveUpdateVideoLiveBar = () => {
  const [liveContent, ackContent] = useLiveEventContent("video-live-bar");
  if (!liveContent) return null;

  return <VideoLiveBar customFields={liveContent} dismiss={ackContent} />;
};

export const VideoLiveBar = ({ customFields, dismiss }) => {
  const videoFieldsContent = customFields || {};
  const {
    displayWithoutVideo,
    customBtnText,
    customPrimaryVideo = "Video 1"
  } = videoFieldsContent;
  const { isAdmin } = useFusionContext();
  const { isMobile: isMobileUrlParam } = useAssemblerContext();
  const [expanded, setExpanded] = useState(false);
  const [muted, setMuted] = useState(true);
  const [playlistClicked, setPlaylistClicked] = useState(false);
  const [primaryVideo, setPrimaryVideo] = useState(customPrimaryVideo);

  // We have access to isMobile url parameter on SSR so we don't have
  // to wait for window to be defined
  const isMobile = isMobileUrlParam;

  useEffect(() => {
    setPrimaryVideo(customPrimaryVideo);
  }, [customPrimaryVideo]);

  const shouldAutoplay = () => !isAdmin;

  const videoConfig = {
    autoplay: shouldAutoplay(),
    customBtnText,
    isAdmin,
    muted
  };

  const { legitVids, listOfConfigs, listOfData } = useLiveVideoData(
    videoFieldsContent,
    videoConfig,
    primaryVideo
  );

  const primaryVideoData = legitVids[0];
  const id = get(primaryVideoData, "videoData._id");

  useDismissIfEnded(videoFieldsContent && id ? id : null, dismiss);

  const getReactKey = ({ headline, headlinePrefix, alternateArt }) =>
    `${id}-${customBtnText}-${headline}-${headlinePrefix}-${
      isAdmin ? alternateArt : ""
    }`;

  const handleExpand = () => {
    setExpanded(true);
    setMuted(false);
  };

  const handleClose = () => {
    setExpanded(false);
  };

  const handlePlaylistClick = (videoLabel) => {
    setPrimaryVideo(videoLabel);
    setPlaylistClicked(true);
    setMuted(false);
  };

  const expandedClass = expanded ? "expanded" : "";
  const showVideo = !displayWithoutVideo && id && (expanded || !isMobile);
  const textWrapperClass = getClasses(`${expandedClass}`, {
    "no-video": displayWithoutVideo,
    "pb-sm": expanded
  });

  const expandMinToggleClass =
    "relative flex items-center justify-center pointer bold font-xxxxs";

  return (
    <Fragment>
      {isAdmin && (
        <HandleFetchErrors lists={{ livebar: { listOfConfigs, listOfData } }} />
      )}
      {!legitVids.length && isAdmin && (
        <Warning
          message={
            "Add a live video url to activate the Video Live Bar feature."
          }
          level="info"
        />
      )}
      {legitVids.length && !!videoFieldsContent ? (
        <StyledBar
          className={"flex video-live-bar"}
          data-link-group="video-live-bar"
        >
          <div
            className={`w-100 ${getClasses("live-bar")}`}
            style={{
              maxWidth: "auto",
              height: displayWithoutVideo ? "auto" : ""
            }}
          >
            <div
              className={`${getClasses("live-wrapper flex", {
                expanded,
                "no-video": displayWithoutVideo
              })}`}
            >
              <div className="flex live-wrapper-contents">
                {showVideo && (
                  <div
                    className={`live-player-wrapper ${expandedClass} ma-sm ${
                      expanded ? "" : "dn-s"
                    }`}
                  >
                    <Video
                      key={getReactKey(legitVids[0])}
                      {...legitVids[0]}
                      playlistClicked={playlistClicked}
                    />
                  </div>
                )}
                <div
                  className={`live-text-wrapper flex mt-sm mb-sm ml-sm z-1 ${textWrapperClass}`}
                >
                  <Headline
                    {...legitVids[0]}
                    videoLabel={get(legitVids[0], "videoData.videoLabel")}
                    isAdmin={isAdmin}
                    className={`mr-auto ${expandedClass}`}
                  />
                  <button
                    aria-label="close"
                    onClick={dismiss}
                    className="ml-sm mr-sm mb-sm pa-0 self-baseline pointer b-none close-video-button"
                  >
                    <Icon
                      label="close"
                      style={{
                        opacity: "80%",
                        fill: theme.colors["gray700-static"]
                      }}
                      className="fill-current"
                    >
                      <Close />
                    </Icon>
                  </button>
                </div>
              </div>
              <button
                aria-label="close"
                onClick={dismiss}
                className="ma-sm pa-0 self-baseline pointer b-none close-video-button"
              >
                <Icon
                  label="close"
                  className="fill-current"
                  style={{
                    opacity: "80%",
                    fill: theme.colors["gray700-static"]
                  }}
                >
                  <Close />
                </Icon>
              </button>
            </div>
            {legitVids.length > 1 && !displayWithoutVideo && (
              <PlaylistItems
                videoSlot2Props={legitVids[1]}
                videoSlot3Props={legitVids[2]}
                isAdmin={isAdmin}
                legitVids={legitVids}
                handlePlaylistClick={handlePlaylistClick}
                className={expandedClass}
              />
            )}
            {!displayWithoutVideo && !expanded && (
              <StyledToggleBar
                onClick={handleExpand}
                className={expandMinToggleClass}
              >
                Expand
                <Icon
                  label="show-more"
                  className="pl-xxs"
                  style={{ fill: theme.colors["gray200-static"] }}
                  size={150}
                >
                  <ChevronDown />
                </Icon>
              </StyledToggleBar>
            )}
            {!displayWithoutVideo && expanded && (
              <StyledToggleBar
                onClick={handleClose}
                className={expandMinToggleClass}
              >
                Close
                <Icon
                  label="show-less"
                  className="pl-xxs"
                  style={{ fill: theme.colors["gray200-static"] }}
                  size={150}
                >
                  <ChevronUp />
                </Icon>
              </StyledToggleBar>
            )}
          </div>
        </StyledBar>
      ) : null}
    </Fragment>
  );
};

VideoLiveBar.propTypes = LiveVideoPropTypes;
export default LiveUpdateVideoLiveBar;
